export const ConfirmarVenta = (props) => {
    const { productos } = props
        
    return (        
        <div className="ventanaEmergente">
            <div className="confirmarVenta">
            <h2>Confirmar venta</h2>
            <p>Cliente: {props.cliente.nombre} {props.cliente.identificacion}</p>
            <table className="productosConfirmarVenta">
                <tbody>
                <tr>
                    <td></td>
                    <td>nombre</td>
                    <td>cantidad</td>
                    <td>subtotal</td>
                </tr>
                {
                    productos.map((prod) => {                    
                        
                        return (                           
                            <tr key={prod.codigo}>
                                <td>
                                    <img src={prod.imagen} alt='imagen no disponible' width="35" height="25"/>         
                                </td>
                                <td>
                                    {prod.nombre}
                                </td>
                                <td>
                                    {prod.cantidad}
                                </td>
                                <td>
                                    ${prod.subtotal}
                                </td>                            
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
            <p>Pago: {props.pago.metodo}  ${props.pago.valor}</p>
            <h3>
                Total: ${props.total}
            </h3>
            <p>Cambio: ${props.pago.valor - props.total}</p>
            <button className='botonGeneral' onClick={props.hacerVenta}>Confirmar</button>
            <button className='botonGeneral' onClick={props.actionCancelar}>Cancelar</button>
            </div>
        </div>
    )
} 

export default ConfirmarVenta;