import React from 'react'
import PropTypes from 'prop-types'

export const ProductoVenta = (props) => {
  const { nombre, descripcion, inventario, precioVenta, imagen } = props.object
  return (
    <div onClick={props.addProdVenta} className='producto'>
      <h4>{nombre}</h4>
      <img src={imagen} alt="Imagen no disponible" width="140" height="100"/>
      <div className="descripcion">{descripcion}</div>
      <div><b>${precioVenta}</b></div>
      <div>Cant. {inventario}</div>
    </div>
  )
}

ProductoVenta.propTypes = {
  addProdVenta: PropTypes.func,
  object: PropTypes.object
}

export default ProductoVenta
