import React, { useState, useEffect } from 'react'
import { Filter } from './components/Filter.js'
import { Paginator } from './components/Paginator.js'
import { CrudForm } from './components/CrudForm'
import { Notificacion } from './components/notifications/Notificacion.js'
import { Button } from './components/Button.js'
import { Usuario } from './components/Usuario.js'
import {
  filterFunction,
  sortData,
  dataToSet,
  duplicateObj
} from './components/filterFunction.js'
import repoInterface from './repositories/repoInterface'
import { formClick } from './components/forms/utils.js'
import { crud } from './functions/crud.js'
import { duplicateMessage } from './components/notifications/utils.js'

const Usuarios = () => {
  const usuarioInit = {
    username: '',
    nombre: '',
    identificacion: '',
    telefono: '',
    direccion: {
      pais: '',
      ciudad: '',
      direccion: ''
    },
    rol: '',
    password: 'salainen',
    email: '',
    activo: true
  }
  const formProps = {
    username: { text: 'Username', type: 'text' },
    nombre: { text: 'Nombre', type: 'text' },
    identificacion: { text: 'Identificacion', type: 'text' },
    telefono: { text: 'Telefono', type: 'text' },
    direccion: { text: 'Direccion', type: 'text' },
    pais: { text: 'Pais', type: 'text' },
    ciudad: { text: 'Ciudad', type: 'text' },
    rol: { text: 'Rol', type: 'text' },
    password: { text: 'Password', type: 'password' },
    email: { text: 'Email', type: 'email' }
  }

  const usuarioRequired = {
    username: 'username',
    nombre: 'nombre',
    identificacion: 'identificacion',
    telefono: 'telefono',
    direccion: 'direccion',
    pais: 'pais',
    ciudad: 'ciudad',
    rol: 'rol',
    password: 'password',
    email: 'email'
  }

  const usuarioUnique = {
    username: 'username',
    identificacion: 'identificacion',
    telefono: 'telefono',
    direccion: 'direccion',
    email: 'email'
  }

  const [usuarios, setUsuarios] = useState([])
  const [filter, setFilter] = useState([])
  const [newUsuario, setNewUsuario] = useState(usuarioInit)
  const [showForm, setShowForm] = useState('')
  const [message, setMessage] = useState({ value: '', type: '' })
  const [formFilter, setFormFilter] = useState([])

  useEffect(() => {
    repoInterface.getAll('usuario')
      .then(data => {
        setUsuarios(data.sort(sortData))
        setFilter(data
          .filter(cli => cli.activo)
          .sort(sortData))
      })
  }, [])

  const handleSearch = (filterObject) => {
    const newArray = filterFunction(filterObject.value)
      .isActive(filterFunction(filterObject.value)
        .hasValue(usuarios))
    setFilter(newArray)
  }

  const handleSearchForm = (filterObject) => {
    const newArray = filterFunction(filterObject.value)
      .hasValue(usuarios)
    setFormFilter(newArray)
  }

  const handleClickForm = (info) => {
    const values = formClick(info)
    setShowForm(values[info.tipo].showForm)
    setNewUsuario(values[info.tipo].newClient)
    setMessage(values[info.tipo].message)
  }

  const manageMessage = (messageObj) => ({
    ephemeral: () => {
      setMessage(messageObj)
      setTimeout(() => {
        setMessage({ value: '', type: '' })
      }, 3000)
    },
    confirmation: () => {
      setMessage(messageObj)
    }
  })

  const actionStateChange = () => ({
    success: (dataToSet, setNewObject, messageObj) => {
      setUsuarios(dataToSet)
      setFilter(dataToSet)
      setNewUsuario(setNewObject)
      setShowForm('')
      manageMessage(messageObj).ephemeral()
    },
    error: (messageObj) => {
      setShowForm('')
      manageMessage(messageObj).ephemeral()
    },
    duplicate: (messageObj) => {
      setShowForm('')
      manageMessage(messageObj).confirmation()
    }
  })

  const crudActions = crud(dataToSet, repoInterface, actionStateChange)

  const addUsuario = (usuarioObject) => {
    const duplicates = duplicateMessage(
      duplicateObj(usuarioObject, usuarioUnique, usuarios))

    if (duplicates.length === 0) {
      crudActions.add(usuarioObject, usuarioInit, filter, 'usuario')
    } else {
      actionStateChange().duplicate({
        value: duplicates, type: 'repeated'
      })
    }
  }

  const editUsuario = (usuarioObject) => {
    crudActions.edit(usuarioObject, usuarioInit, usuarios, filter, 'usuario')
  }

  const deleteUsuario = (id) => {
    crudActions.eliminate(id, usuarioInit, usuarios, filter, 'usuario')
  }

  const actions = {
    agregar: addUsuario,
    editar: editUsuario
  }

  return <>
    <Filter
      handleChangeFilter={(filterObject) => handleSearch(filterObject)}
      data={usuarios}
      type={'usuario'}
    />
    <section>
      <Notificacion
        {...message}
        actionEditar={() => handleClickForm({ tipo: 'editar', datos: newUsuario })}
        actionActivar={() => handleClickForm({ tipo: 'editar', datos: newUsuario })}
        actionEliminar={() => deleteUsuario(newUsuario.id)}
        actionCancelar={() => handleClickForm({ tipo: 'cancelar', datos: usuarioInit })}
      />
      <div className="opcionesVistaSuperior">
        <Button
          type={'botonPrincipalSuperior'}
          setValue={() => handleClickForm({ tipo: 'agregar', datos: newUsuario })}
          text='Nuevo usuario'
        />
        <Button
          type={'botonPrincipalSuperiorPeque'}
          setValue={() => handleClickForm({ tipo: 'agregar', datos: usuarioInit })}
          text={'+'}
        />
      </div>
      <Paginator
        type={'usuario'}
        productos={Usuarios}
        filteredData={filter}
        objSchema={usuarioInit}
        page={1}
        editarObj={(editObject) => (handleClickForm(editObject))}
        eliminarObj={(eliminarObject) => (handleClickForm(eliminarObject))}
        vista={'usuarios'}
      >
          <Usuario />
      </Paginator>
    </section>
    {showForm in actions &&
      <CrudForm
        type={'usuario'}
        title={showForm}
        setForm={actions[showForm]}
        setNewObject={setNewUsuario}
        obj={newUsuario}
        uniqueProperties={usuarioUnique}
        reqPropperties={usuarioRequired}
        formProperties={formProps}
        filterInfo={formFilter}
        clickFilterList={(formObject) => handleClickForm(formObject)}
        formFilter={(filterObject) => handleSearchForm(filterObject)}
        actionCancelar={() => handleClickForm({ tipo: 'cancelar', datos: usuarioInit })}
      />
    }
  </>
}

export default Usuarios
