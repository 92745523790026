import { useState } from 'react'

export const Categorias = ({
  handleChangeCategoria,
  data
}) => {

  const [ categoria, setCategoria ] = useState({value: '', state: true})
  let categorias = ['Todos']

  function sortArray(x,y) {
    if (x < y) {return -1;}
    if (x > y) {return 1;}
    return 0;
  } 

  const handleClickCategoria = (event) => {
    event.preventDefault()
    //se define el nuevo estado del filtro por caegorías 
    const filterCate = {
      value: event.target.text,
      state: (() => !(categoria.state))
    }    

    //se modifica el estado de cada filtro 
    setCategoria(filterCate);
    handleChangeCategoria(filterCate)
  }    
    
    data.map((producto)=>{
      if (producto.activo === true){
        if (categorias.find(n => n === producto.categoria))      
        return categorias
        else {
          return categorias = categorias.concat(producto.categoria)
        }
      }
      return ''
    })

    return (
      <div>
      <header id='header'>
        <nav className="nav-menu">
          <ul>
            {
              categorias
              .sort(sortArray)
              .map((categoria) => (
                <li key={categoria}><a href='/' onClick={handleClickCategoria}>{categoria}</a></li>
              ))
            }
          </ul>
        </nav>
      </header>
      <header id='headerPeque'>
        <nav>
          <ul className="nav-menuPeque">
            {
              categorias
              .sort(sortArray)
              .map((categoria) => (
                <div key={categoria} className='categoriaPeque'>
                <li><a href='/' onClick={handleClickCategoria}>{categoria}</a></li>
                </div>
              ))
            }
          </ul>
        </nav>
      </header>
      </div>
    )
  }

export default Categorias;