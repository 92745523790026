import fechaFormatoLocal from "../functions/fechaFormatoLocal"

export const FilaReporteDevolucionesPeque = (props) => {
    
    const {id, fecha,dineroDevuelto} = props.producto

    const fechaFormateada = fechaFormatoLocal(fecha)

    return (
            <li key={id}>{`${fechaFormateada.dia}-${fechaFormateada.mes}-${fechaFormateada.year} ${fechaFormateada.hora}`} - {dineroDevuelto.valor}</li>
    )
}