import React, { useState, useEffect } from 'react'
import { FiShoppingCart } from 'react-icons/fi'
import { Notificacion } from './components/notifications/Notificacion.js'
import { duplicateMessage } from './components/notifications/utils.js'
import { ClienteRegRapido } from './components/ClienteRegRapido'
import { ProductoVenta } from './components/ProductoVenta.js'
import { ConfirmarVenta } from './components/ConfirmarVenta'
import repoInterface from './repositories/repoInterface'
import { formClick } from './components/forms/utils.js'
import { Categorias } from './components/Categorias.js'
import { Paginator } from './components/Paginator.js'
import productosService from './services/productos'
import { VentaPrev } from './components/VentaPrev'
import { Filter } from './components/Filter.js'
import { crud } from './functions/crud.js'

import {
  filterFunction,
  sortData,
  dataToSet,
  duplicateObj
} from './components/filterFunction.js'

const Ventas = () => {
  const clienteInit = {
    nombre: '',
    identificacion: '',
    telefono: '',
    correo: '',
    direccion: {
      pais: '',
      ciudad: '',
      dir: ''
    },
    activo: true
  }
  const clienteUnique = {
    nombre: 'nombre',
    identificacion: 'identificacion',
    telefono: 'telefono',
    correo: 'correo'
  }
  const ventaInit = {
    productos: [],
    cliente: clienteInit,
    total: [0],
    pago: { metodo: '', valor: '' }
  }
  const prodInitState = {
    nombre: '',
    codigo: '',
    descripcion: '',
    inventario: '',
    costo: '',
    precioVenta: '',
    categoria: '',
    imagen: 'img/not_available.jpg',
    activo: true
  }

  const [productos, setProductos] = useState([])
  const [clientes, setClientes] = useState([])
  const [newCliente, setNewCliente] = useState(clienteInit)
  const [cliFilter, setCliFilter] = useState([])
  const [venta, setVenta] = useState(ventaInit)
  const [showForm, setShowForm] = useState('')
  const [message, setMessage] = useState({ value: '', type: '' })
  const [filter, setFilter] = useState([])
  const [mostrarVenta, setMostrarVenta] = useState(false)

  useEffect(() => {
    repoInterface.getAll('producto')
      .then(data => {
        setProductos(data.sort(sortData))
        setFilter(data
          .filter(cli => cli.activo)
          .filter(cli => cli.inventario > 0)
          .sort(sortData))
      })
    repoInterface.getAll('cliente')
      .then(data => {
        setClientes(data.sort(sortData))
        setCliFilter(data
          .filter(cli => cli.activo)
          .sort(sortData))
      })
  }, [])

  const manageMessage = (messageObj) => ({
    ephemeral: () => {
      setMessage(messageObj)
      setTimeout(() => {
        setMessage({ value: '', type: '' })
      }, 3000)
    },
    confirmation: () => {
      setMessage(messageObj)
    }
  })

  const ventaStateChange = () => ({
    success: (dataToSet, setNewObject, messageObj) => {
      setVenta(setNewObject)
      setShowForm('')
      manageMessage(messageObj).ephemeral()
    },
    error: (messageObj) => {
      setShowForm('')
      manageMessage(messageObj).ephemeral()
    }
  })

  const actionStateChange = () => ({
    success: (dataToSet, setNewObject, messageObj) => {
      setClientes(dataToSet)
      setNewCliente(setNewObject)
      setShowForm('')
      manageMessage(messageObj).ephemeral()
    },
    error: (messageObj) => {
      setShowForm('')
      manageMessage(messageObj).ephemeral()
    },
    duplicate: (messageObj) => {
      setShowForm('')
      manageMessage(messageObj).confirmation()
    }
  })

  const handleCategorias = (categoria) => {
    setFilter(productos.filter(producto =>
      categoria.value === 'Todos' ||
      producto.categoria.toLowerCase()
        .startsWith(categoria.value.toLowerCase())
    ))
  }

  const handleClickForm = (info) => {
    const values = formClick(info)
    setShowForm(values[info.tipo].showForm)
    setNewCliente(values[info.tipo].newClient)
    setMessage(values[info.tipo].message)
  }

  const handleSearch = (filterObject) => {
    const newArray = filterFunction(filterObject.value)
      .isActive(filterFunction(filterObject.value)
        .hasValue(productos))
    setFilter(newArray)
  }

  const handleSearchCliente = (filterObject) => {
    const newArray = filterFunction(filterObject.value)
      .hasValue(clientes)
    setCliFilter(newArray)
    setNewCliente(filterObject.value)
    setVenta({
      ...venta,
      cliente: {
        ...venta.cliente,
        identificacion: filterObject.value
      }
    })
  }

  const handleClickCliente = (cliente) => {
    setVenta({ ...venta, cliente })
    setNewCliente(clienteInit)
    setCliFilter([{ ...cliente, selected: true }])
  }

  const clienteActions = crud(dataToSet, repoInterface, actionStateChange)

  const addCliente = (clienteObject) => {
    const duplicates = duplicateMessage(
      duplicateObj(clienteObject, clienteUnique, clientes))

    if (duplicates.length === 0) {
      clienteActions.add(clienteObject, clienteInit,
        filter, 'cliente', 'nombre')
    } else {
      actionStateChange().duplicate({
        value: duplicates, type: 'repeated'
      })
    }
  }

  const editCliente = (clienteObject) => {
    clienteActions.edit(clienteObject, clienteInit,
      clientes, cliFilter, 'cliente', 'nombre')
  }

  const handleClickProd = (prodObject) => {
    const producto = {
      ...prodObject,
      cantidad: 1,
      subtotal: prodObject.precioVenta
    }
    const prodToModify = venta.productos.find(prod =>
      prod.nombre.trim().toLowerCase() ===
      prodObject.nombre.trim().toLowerCase()
    )
    if (!prodToModify) {
      setVenta({
        ...venta,
        productos: venta.productos.concat(producto),
        total: venta.total.concat(producto.subtotal)
      })
    } else if (prodToModify.cantidad < prodObject.inventario) {
      producto.cantidad = prodToModify.cantidad + 1
      producto.subtotal = prodToModify.precioVenta * producto.cantidad
      setVenta({
        ...venta,
        productos: venta.productos
          .map(prod => prod.nombre
            .trim().toLowerCase() !==
            prodToModify.nombre.trim().toLowerCase()
            ? prod
            : producto),
        total: venta.total
          .concat(prodToModify.precioVenta)
      })
    }
  }

  const ventaActions = crud(dataToSet, repoInterface, ventaStateChange)

  const addVenta = (event) => {
    event.preventDefault()
    const ventaToAdd = {
      productos: venta.productos
        .map(prod => ({
          productoId: prod.id,
          cantidad: prod.cantidad,
          subtotal: prod.subtotal
        })),
      cliente: venta.cliente.id,
      pago: venta.pago,
      total: venta.total.reduce((a, b) => a + b)
    }
    ventaActions.add(ventaToAdd, ventaInit, [], 'venta')
    for (const i in venta.productos) {
      const prodToEdit = productos
        .find(prod => (prod.id === venta.productos[i].id))
      const newCantidad = prodToEdit.inventario - venta.productos[i].cantidad
      const productoNew = { ...prodToEdit, inventario: newCantidad }
      const dataSet = dataToSet(filter)
      productosService
        .update(venta.productos[i].id, productoNew)
        .then(prod => setFilter(dataSet.edit(productoNew, prod).newData))
        .catch(error => actionStateChange().error({
          value: error.response.data.error,
          type: 'failure'
        }))
    }
  }

  const actions = {
    agregarCliente: { title: 'Agregar', action: addCliente },
    editarCliente: { title: 'Editar', action: editCliente }
  }

  return (
    <div>
      <Categorias
        handleChangeCategoria={(cateObject) => handleCategorias(cateObject)}
        data={productos}
      />
      <div>
        <Filter
          handleChangeFilter={(filterObject) => handleSearch(filterObject)}
          data={productos}
          type={'producto'}
        />
        <Notificacion
          {...message}
          actionEditar={() => handleClickForm({ tipo: 'editarCliente', datos: newCliente })}
          actionCancelar={() => handleClickForm({ tipo: 'cancelar', datos: '' })}
        />
        <div className="opcionesVistaSuperior">
          <div className='containerCarritoVenta' onClick={() => setMostrarVenta(true)}>
          <button className='conteoVenta'>{venta.productos.length}</button>
          <button className='botonCarritoVenta'><i><FiShoppingCart/></i></button>
          </div>
        </div>
        <div className={mostrarVenta ? 'containerVentaPrevEmergente' : ''}>
          <VentaPrev
            venta={venta}
            clientesFilter={(filterObject) => handleSearchCliente(filterObject)}
            clientes={cliFilter}
            clickCliente={(cliObject) => handleClickCliente(cliObject)}
            agregarCliente={() => handleClickForm({ tipo: 'agregarCliente', datos: newCliente })}
            activateCliente={(formObj) => handleClickForm(formObj)}
            handleVentaChange={(venta) => setVenta(venta)}
            confirmarVenta={() => handleClickForm({ tipo: 'confirmarVenta', datos: '' })}
            tipo={mostrarVenta ? 'ventaPrevPeque' : 'ventaPrevia'}
            mostrarVenta={(value) => setMostrarVenta(value)}
          />
        </div>
        <section>
          <div className='contenedorVenta'>
            <Paginator
              type={'producto'}
              filteredData={filter}
              objSchema={prodInitState}
              page={1}
              addProdVenta={(prodObject) => (handleClickProd(prodObject))}
              vista={'productosVenta'}
            >
                <ProductoVenta />
            </Paginator>
          </div>
        </section>
      </div>
      {(showForm === 'confirmarVenta' &&
        <ConfirmarVenta
            cliente={venta.cliente}
            productos={venta.productos}
            pago={venta.pago}
            total={venta.total.reduce((a, b) => a + b)}
            hacerVenta={addVenta}
            actionCancelar={() => handleClickForm({ tipo: 'cancelar', datos: '' })}
        />) ||
        (showForm in actions &&
           <ClienteRegRapido
              title={actions[showForm].title}
              setForm={actions[showForm].action}
              cliente={newCliente}
              infoFormFilter={cliFilter}
              handleClickFormFilter={(formObject) => handleClickForm(formObject)}
              handleFormFilter={(filterObject) => handleSearchCliente(filterObject)}
              actionCancelar={() => handleClickForm({ tipo: 'cancelar', datos: '' })}
          />)
      }
    </div>
  )
}

export default Ventas
