import DetalleVenta from "./DetalleVenta"

export const DetalleDevolucion = ({devolucion, cancelar, dataComp}) => {

    const ventaOriginal = dataComp.find(d => d.id === devolucion.ventaOriginal)
    const ventaNueva = devolucion.ventaNueva !== '' ? 
        dataComp.find(d => d.id === devolucion.ventaNueva) :
        ''


    return (
        <div>
            <div className="detalleDevolucion">
                <div>
                    <h3>Venta original</h3>
                    <DetalleVenta
                        tipoReporte={'Devolucion'}
                        venta={ventaOriginal}
                        cancelar={cancelar}
                    />
                </div>
                <div>
                    <h3>Devolucion</h3>
                    <div>               
                        <strong>Fecha: {devolucion.fecha}</strong>     
                        <p>id: {devolucion.id}</p>                
                    </div>
                    <div>                    
                        <p>
                            Usuario: {devolucion.usuario.username}
                        </p>
                    </div>
                    <div>
                        <p>
                            Cliente: {devolucion.cliente.nombre} {devolucion.cliente.identificacion}
                        </p>
                    </div>
                    <div>
                        <table>
                            <tbody>
                                <tr>
                                    <td>producto</td>
                                    <td>cantidad</td>
                                    <td>subtotal</td>                            
                                </tr>
                                {
                                    devolucion.productos.map((prod) => (                            
                                        <tr key={prod.productoId.id}>
                                            <td>{prod.productoId.nombre}</td>
                                            <td>{prod.cantidad}</td>
                                            <td>{prod.subtotal}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        <br></br>
                    </div>                   
                    <div className="total">
                        <p>
                            Metodo de pago: {devolucion.dineroDevuelto.metodo}
                        </p>
                        <p>
                            Total: {devolucion.dineroDevuelto.valor}
                        </p>                  
                    </div>               
                </div>
                
                {
                    ventaNueva !== '' ?
                        <div>
                            <h3>Nueva venta</h3>
                            <DetalleVenta
                                tipoReporte={'Devolucion'}
                                venta={ventaNueva}
                                cancelar={cancelar}
                            /> 
                        </div> :
                    ''
                }
                                               
            </div>
            <div className='accionesForm'>          
                <button className="botonGeneral" onClick={cancelar}>cerrar</button>  
            </div>
        </div>
    )
}

export default DetalleDevolucion