// import Select from 'react-select';
import React, { useState } from 'react'
import { Button } from './Button.js'
import PropTypes from 'prop-types'
import { operation } from '../functions/calculadora.js'
import { FiXCircle, FiTrash2 } from 'react-icons/fi'

export const VentaPrev = (props) => {
  const {
    venta,
    clientes,
    clientesFilter,
    clickCliente,
    agregarCliente,
    activateCliente,
    handleVentaChange,
    confirmarVenta,
    tipo,
    mostrarVenta
  } = props

  const clienteInit = {
    nombre: '',
    identificacion: '',
    telefono: '',
    correo: '',
    direccion: {
      pais: '',
      ciudad: '',
      dir: ''
    },
    activo: true
  }

  const [cliFilter, setCliFilter] = useState({ value: venta.cliente.identificacion, state: false })

  const handleChangeFilter = (event) => {
    event.preventDefault()
    const newFilter = {
      value: event.target.value,
      state: event.target.value !== ''
    }
    setCliFilter(newFilter)
    clientesFilter(newFilter)
  }

  const handleClickCliente = (event) => {
    event.preventDefault()
    const clienteFilt = clientes
      .find(cliente => (
        cliente.identificacion === event.target.lastChild.data
      ))
    clickCliente(clienteFilt)
    setCliFilter({ ...cliFilter, value: '' })
  }

  const handleActivateCliente = (event) => {
    event.preventDefault()
    const articuloToEdit = clientes.find(cli => cli.identificacion === event.target.lastChild.data)
    const formObj = {
      tipo: 'editarCliente',
      datos: articuloToEdit
    }
    activateCliente(formObj)
    setCliFilter({ value: '', state: false })
  }

  const handleCloseCliente = (event) => {
    event.preventDefault()
    setCliFilter({ value: '', state: false })
    clickCliente(clienteInit)
  }

  const handleClickCantidad = (event) => {
    const type = event.target.innerText
    const prodNombre = event.target
      .parentElement.parentElement
      .children[1].innerText.trim().toLowerCase()
    const prodToModify = venta.productos
      .find(n => n.nombre.trim().toLowerCase() === prodNombre)
    let newCant = prodToModify.cantidad
    newCant = operation(type).doOperation(newCant, 1)

    if (newCant < 1) {
      handleVentaChange({
        ...venta,
        productos: venta.productos
          .filter((prod) => prod.nombre.trim().toLowerCase() !==
          prodNombre.trim().toLowerCase()),
        total: venta.total
          .concat(-prodToModify.precioVenta)
      })
    } else {
      const subtotal = prodToModify.precioVenta * newCant
      const modifiedProd = {
        ...prodToModify,
        cantidad: newCant,
        subtotal
      }
      handleVentaChange({
        ...venta,
        productos: venta.productos
          .map(prod => prod.nombre.trim().toLowerCase() !==
          prodNombre.trim().toLowerCase()
            ? prod
            : modifiedProd),
        total: venta.total
          .concat(parseInt(`${type}${prodToModify.precioVenta}`))
      })
    }
  }

  const removeProduct = (prodNombre) => {
    const prodToModify = venta.productos
      .find(n => n.nombre.trim().toLowerCase() === prodNombre.trim().toLowerCase())
    handleVentaChange({
      ...venta,
      productos: venta.productos
        .filter((prod) => prod.nombre.trim().toLowerCase() !==
        prodNombre.trim().toLowerCase()),
      total: venta.total
        .concat(-prodToModify.precioVenta)
    })
  }

  const handleMetodoPago = (event) => {
    handleVentaChange({
      ...venta,
      pago: {
        ...venta.pago,
        metodo: event.target.value
      }
    })
  }

  const handleValorPago = (event) => {
    event.preventDefault()

    if (event.target.value === '') {
      handleVentaChange({
        ...venta,
        pago: { ...venta.pago, valor: 0 }
      })
    } else {
      handleVentaChange({
        ...venta,
        pago: {
          ...venta.pago,
          valor: parseInt(event.target.value)
        }
      })
    }
  }

  const total = () => venta.total.reduce((a, b) => a + b)

  return (
    <div className={`${tipo}Container`}>
      <div className={tipo}>
      {tipo === 'ventaPrevPeque' &&
        <button className='botonCerrar' onClick={() => mostrarVenta(false)}><i><FiXCircle/></i></button>
      }
        <h2>Detalles Venta</h2>
        <div>
          Buscar cliente
          <input type='text' onChange={handleChangeFilter} value={cliFilter.value}></input>
          {cliFilter.state &&
            (clientes.length === 0
              ? <div>
                  <button className="botonGeneral" onClick={agregarCliente}>Añadir</button>
                </div>
              : <div>
                {clientes.map((cliente) =>
                  cliente.activo
                    ? cliente.selected
                      ? <div key={cliente.identificacion}>
                          {cliente.nombre} {cliente.identificacion}
                          <button className="botonPeque" onClick={handleCloseCliente}>x</button>
                        </div>
                      : <div key={cliente.identificacion} onClick={handleClickCliente}>{cliente.nombre} {cliente.identificacion}</div>
                    : <div key={cliente.identificacion} onClick={handleActivateCliente}>inactivo {cliente.identificacion}</div>
                )}
                </div>
            )}
        </div>
        <table>
          <tbody>
          {venta.productos.map((prod) =>
            <tr key={prod.codigo}>
              <td>
                <img src={prod.imagen} alt='imagen no disponible' width="35" height="25"/>
              </td>
              <td className="nombreVenta">
                {prod.nombre}
              </td>
              <td>
                <button className="botonPeque" onClick={handleClickCantidad}>-</button>
                {prod.cantidad}
                {prod.cantidad < prod.inventario && <button className="botonPeque" onClick={handleClickCantidad}>+</button>}
                <button className='botonTrash' onClick={() => removeProduct(prod.nombre)}><i><FiTrash2/></i></button>
              </td>
              <td>
                ${prod.subtotal}
              </td>
            </tr>
          )}
          </tbody>
        </table>
        <select onChange={handleMetodoPago}>
          <option>-</option>
          <option>Efectivo</option>
        </select>
        <input type='text' onChange={handleValorPago}></input>
        <h3>Total: ${total()}</h3>
        {venta.cliente.identificacion !== '' &&
          total() > 0 &&
            <Button type={'botonPrincipal'} setValue={confirmarVenta} text='confirmar'/>
        }
      </div>
    </div>
  )
}

export default VentaPrev

VentaPrev.propTypes = {
  venta: PropTypes.object,
  clientes: PropTypes.array,
  clientesFilter: PropTypes.func,
  clickCliente: PropTypes.func,
  agregarCliente: PropTypes.func,
  activateCliente: PropTypes.func,
  handleVentaChange: PropTypes.func,
  confirmarVenta: PropTypes.func,
  tipo: PropTypes.string,
  mostrarVenta: PropTypes.func
}
