export const operation = (type) => {
  const operations = (a, b) => ({
    '+': a + b,
    '-': a - b,
    '*': a * b,
    '/': a / b
  })
  const doOperation = (a, b) => operations(a, b)[type]

  return { doOperation }
}
