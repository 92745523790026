import fechaFormatoLocal from "../functions/fechaFormatoLocal"

export const FilaReporteDetalleVentaPeque = (props) => {
    
    const {id, fecha,total} = props.producto

    const fechaFormateada = fechaFormatoLocal(fecha)

    return (
            <li key={id}>{`${fechaFormateada.dia}-${fechaFormateada.mes}-${fechaFormateada.year} ${fechaFormateada.hora}`} - {total}</li>                              
    )
}