import React, { useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import PropTypes from 'prop-types'

export const Filter = (props) => {
  const {
    handleChangeFilter
  } = props

  const [filter, setFilter] = useState({ value: '', state: false })

  const changeFilter = (event) => {
    event.preventDefault()

    const newFilter = {
      value: event.target.value,
      state: event.target.value === ''
    }

    setFilter(newFilter)
    handleChangeFilter(newFilter)
  }

  return <div className='busqueda'>
    <input type='text' onChange={changeFilter} value={filter.value}/><i className='searchIcon'><FiSearch/></i>
  </div>
}

export default Filter

Filter.propTypes = {
  handleChangeFilter: PropTypes.func
}
