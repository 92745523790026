import fechaFormatoLocal from "../functions/fechaFormatoLocal"
import { useState } from "react";
import { FiXCircle } from "react-icons/fi";

export const FilaReporteDevoluciones = (props) => {
    
    const {id,fecha,dineroDevuelto} = props.producto

    const [ botonDetalle, setBotonDetalle ] = useState(false)

    const fechaFormateada = fechaFormatoLocal(fecha)

    return (
        props.type === 'grande' ?
        <tr key={id}>
            <td>{id}</td>
            <td>{`${fechaFormateada.dia}-${fechaFormateada.mes}-${fechaFormateada.year} ${fechaFormateada.hora}`}</td>
            <td>$ {dineroDevuelto.valor}</td>
            <td>
                <button className="botonGeneral" onClick={props.accionDetalle}>detalle</button>
            </td>                                
        </tr>
        :
        <div>
          <li onClick={() => setBotonDetalle(true)} key={id}>{`${fechaFormateada.dia}-${fechaFormateada.mes}-${fechaFormateada.year} ${fechaFormateada.hora}`} - ${dineroDevuelto.valor}</li>
          {
            botonDetalle === true ?
            <div className="ventanaEmergente">
              <div className="formulario">
            <button className='botonCerrar' onClick={() => setBotonDetalle(false)}><i><FiXCircle/></i></button>
            <table>
              <tbody>
                <tr>
                  <td>id:</td>
                  <td>{id}</td>
                </tr>
                <tr>
                  <td>fecha:</td>
                  <td>{`${fechaFormateada.dia}-${fechaFormateada.mes}-${fechaFormateada.year} ${fechaFormateada.hora}`}</td>
                </tr>
                <tr>
                  <td>total:</td>
                  <td>{dineroDevuelto.valor}</td>
                </tr>                                
              </tbody>
            </table>
            <div>
              <button className='botonGeneral' onClick={props.actionEditar}>editar</button>
              <button className='botonGeneral' onClick={props.actionEliminar}>eliminar</button>
            </div>
            </div>
          </div>
         : ''
        } 
      </div>
    )
}