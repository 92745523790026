import React from 'react'
import PropTypes from 'prop-types'
import { FiXCircle } from 'react-icons/fi'

export const Notificacion = (props) => {
  const {
    value,
    type,
    actionEditar,
    actionCancelar,
    actionEliminar
  } = props

  const buttons = {
    editar: {
      text: 'editar',
      className: 'botonGeneral',
      action: actionEditar
    },
    eliminar: {
      text: 'eliminar',
      className: 'botonGeneral',
      action: actionEliminar
    }
  }

  const messageTypes = {
    success: {
      className: 'successMessage',
      buttons: false
    },
    failure: {
      className: 'failureMessage',
      buttons: false
    },
    delete: {
      className: 'confirmarAccion',
      parentClassName: 'ventanaEmergente',
      buttons: {
        eliminar: buttons.eliminar
      }
    },
    innactive: {
      className: 'ventanaEmergente',
      buttons: {
        editar: buttons.editar
      }
    },
    wrongCredentials: {
      className: 'failureMessage',
      buttons: false
    },
    anular: {
      className: 'confirmarAccion',
      parentClassName: 'ventanaEmergente',
      buttons: false
    },
    repeated: {
      className: 'confirmarAccion',
      parentClassName: 'ventanaEmergente',
      buttons: {
        editar: buttons.editar
      }
    }
  }

  const messagesArray = typeof value === 'string' ? [value] : value

  return <div>
    {
      type in messageTypes &&
        <div className={messageTypes[type].parentClassName || ''} >
          <div className={messageTypes[type].className}>
          {messageTypes[type].buttons &&
            <button className='botonCerrar' onClick={actionCancelar}><i><FiXCircle/></i></button>}
          {messagesArray.map(val => (
            <div key={val}>
              {val}
              <br></br>
              {Object.entries(messageTypes[type]?.buttons).map(([k, v]) =>
                <button key={k} className={v.className} onClick={v.action}>{v.text}</button>
              )}
            </div>
          ))
          }
          </div>
        </div>
    }
  </div>
}

Notificacion.propTypes = {
  type: PropTypes.string,
  actionEditar: PropTypes.func,
  actionCancelar: PropTypes.func,
  actionEliminar: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ])
}

export default Notificacion
