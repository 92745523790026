import axios from 'axios'

const apiUrl = process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_DEV_URL
    : process.env.REACT_APP_PROD_URL
const baseUrl = `${apiUrl}/api/password-reset`

const verifyEmail = async email => {
    const response = await axios.post(baseUrl, email)
    return response.data
}

const resetPassword = async request => {
    const url = `${baseUrl}/${request.id}/${request.token}`
    const response = await axios.post(url, request.credentials)
    return response.data
}

const exportedOject = { verifyEmail, resetPassword }

export default exportedOject
