import React, { useState } from 'react'
import passResetService from '../services/passwordReset'
import { useNavigate } from 'react-router-dom'
import { Notificacion } from '../components/notifications/Notificacion.js'

const EmailVerificationForm = () => {
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState({ value: '', type: '' })
    const navigate = useNavigate()

    const handleVerifyEmail = async (event) => {
        event.preventDefault()
        passResetService.verifyEmail({ email })
            .then(data => {
                setMessage(
                    {
                        value: data,
                        type: 'success'
                    }
                )
                setTimeout(() => {
                    setMessage({ value: '', type: '' })
                    navigate('/')
                }, 4000)
            })
            .catch(error => {
                console.log(error)
                setMessage(
                    {
                        value: 'El correo no se encuentra registrado',
                        type: 'failure'
                    }
                )
                setTimeout(() => {
                    setMessage({ value: '', type: '' })
                }, 4000)
            })
    }

    return (
        <div>
            <Notificacion
                {...message}
            />
            <div className='emailVerification'>
                <div className='emailVerificationForm'>
                    <h2>Cambiar contraseña</h2>
                    <form onSubmit={handleVerifyEmail}>
                        <div className="emailVerify" style={{ textAlign: 'center' }}>
                            Email:
                            <input
                                type="email"
                                value={email}
                                name="Email"
                                onChange={({ target }) => setEmail(target.value)}
                            />
                        </div>
                        <button className='botonPrincipal' type="submit">enviar</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EmailVerificationForm
