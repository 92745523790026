import React, { useState, useEffect } from 'react'
import Ventas from './Ventas'
import Inventario from './Inventario'
import Clientes from './Clientes'
import Usuarios from './Usuarios'
import Reportes from './Reportes'
import Dashboard from './Dashboard'
import { Notificacion } from './components/notifications/Notificacion.js'
import { Button } from './components/Button.js'
import productosService from './services/productos'
import clientesService from './services/clientes'
import ventasService from './services/ventas'
import usuariosService from './services/usuarios'
import devolucionesService from './services/devoluciones'
import LoginForm from './components/LoginForm.js'
import loginService from './services/login'
import { FaBars, FaWindowClose } from 'react-icons/fa'
import PasswordResetForm from './components/PasswordResetForm'
import EmailVerificationForm from './components/EmailVerificationForm'
import {
    Routes, Route, Link, Navigate, useNavigate, useLocation
} from 'react-router-dom'

export const App = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [usuario, setUsuario] = useState(undefined)
    const [message, setMessage] = useState({ value: '', type: '' })
    const [showMenu, setShowMenu] = useState(false)

    const padding = {
        padding: 5
    }

    useEffect(() => {
        const loggedUserJSON = window.localStorage.getItem('loggedPosappUser')
        if (loggedUserJSON) {
            const usuario = JSON.parse(loggedUserJSON)
            loginService.setToken(usuario.token)
            loginService.verifyToken()
            .then(user => {
                setUsuario(user)
                ventasService.setToken(user.token)
                clientesService.setToken(user.token)
                productosService.setToken(user.token)
                usuariosService.setToken(user.token)
                devolucionesService.setToken(user.token)
            })
            .catch(error => {
                console.log(error.response.data)
                handleLogOut()
            })        
        }
    }, [])

    const handleLogin = async (event) => {
        event.preventDefault()
        const from = location.state?.from?.pathname || '/'

        try {
            const usuario = await loginService.login({
                username, password
            })

            window.localStorage.setItem(
                'loggedPosappUser', JSON.stringify(usuario)
            )
            ventasService.setToken(usuario.token)
            clientesService.setToken(usuario.token)
            productosService.setToken(usuario.token)
            usuariosService.setToken(usuario.token)
            devolucionesService.setToken(usuario.token)
            setUsuario(usuario)
            setUsername('')
            setPassword('')
            navigate(from, { replace: true })
        } catch (exception) {
            setMessage({ value: 'credenciales incorrectas', type: 'wrongCredentials' })
            setTimeout(() => {
                setMessage({ value: '', type: '' })
            }, 5000)
        }
    }

    const handleLogOut = () => {
        window.localStorage.removeItem('loggedPosappUser', usuario)
        setUsuario(null)
        loginService.setToken(null)
        ventasService.setToken(null)
        clientesService.setToken(null)
        productosService.setToken(null)
        usuariosService.setToken(null)
        devolucionesService.setToken(null)
        navigate('/login')
    }

    // const locationRender = {
    //     '/': <Dashboard />,
    //     '/usuarios': <Usuarios />,
    //     '/inventario': <Inventario />,
    //     '/venta': <Ventas />,
    //     '/clientes': <Clientes />,
    //     '/reportes': <Reportes />
    // }

    const RequireAuth = (element) => {
        if (!usuario || usuario === undefined) {
            return <Navigate to="/login" state={{ from: location }} replace />
        }
        return element
    }

    const loginPage = (element) => {
        const currentLoc = location.state !== null ? location.state.from.pathname : location.pathname

        if (!usuario || usuario === undefined) {
            return (
                <LoginForm
                    loginAction={handleLogin}
                    usernameAction={({ target }) => setUsername(target.value)}
                    passwordAction={({ target }) => setPassword(target.value)}
                    usuario={username}
                    contrasena={password}
                />
            )
        }

        if (currentLoc === '/login') return <Navigate to='/' state={{ from: location }} replace />
        return <Navigate to={currentLoc} state={{ from: location }} replace />
    }
    // const passwordReset = () => {
    //     if(passResetParams.id === '' || !passResetParams.id) return <EmailVerificationForm />
    //     return <PasswordResetForm />
    // }

    return (
        <div>
            <div className='logo'>
                <Link style={padding} to="/">
                    <img src='../../img/valleDeTenza.png' alt="Imagen no disponible"/>
                </Link>
            </div>
            <Notificacion
                {...message}
            />
            {usuario
                ? <div>
                    <button className='menu-boton' onClick={() => setShowMenu(true)}><i><FaBars/></i></button>
                    <nav id='navbar' className='navbar'>
                        <div className='navtab'>
                            <Link style={padding} to="/">inicio</Link>
                        </div>
                        <div className='navtab'>
                            <Link style={padding} to="/usuarios">usuarios</Link>
                        </div>
                        <div className='navtab'>
                            <Link style={padding} to="/inventario">inventario</Link>
                        </div>
                        <div className='navtab'>
                            <Link style={padding} to="/venta">venta</Link>
                        </div>
                        <div className='navtab'>
                            <Link style={padding} to="/clientes">clientes</Link>
                        </div>
                        <div className='navtab'>
                            <Link style={padding} to="/reportes">reportes</Link>
                        </div>
                        <div>
                            <small><em>{usuario.username} </em></small>
                            <Button type={'botonPrincipal'} setValue={handleLogOut} text="logout"/>
                        </div>
                    </nav >
                    { showMenu === true
                        ? <div className="ventanaEmergenteMenu">
                            <div className='confirmarAccion'>
                                <button className='botonCerrar' onClick={() => setShowMenu(false)}><i><FaWindowClose/></i></button>
                                <div className='accionesForm'>
                                    <div className='opcionMenu' onClick={() => setShowMenu(false)}>
                                        <Link style={padding} to="/">inicio</Link>
                                    </div>
                                    <div className='opcionMenu' onClick={() => setShowMenu(false)}>
                                        <Link style={padding} to="/usuarios">usuarios</Link>
                                    </div>
                                    <div className='opcionMenu' onClick={() => setShowMenu(false)}>
                                        <Link style={padding} to="/inventario">inventario</Link>
                                    </div>
                                    <div className='opcionMenu' onClick={() => setShowMenu(false)}>
                                        <Link style={padding} to="/venta">venta</Link>
                                    </div>
                                    <div className='opcionMenu' onClick={() => setShowMenu(false)}>
                                        <Link style={padding} to="/clientes">clientes</Link>
                                    </div>
                                    <div className='opcionMenu' onClick={() => setShowMenu(false)}>
                                        <Link style={padding} to="/reportes">reportes</Link>
                                    </div>
                                </div>
                                <div className='logOutPeque'>
                                    <small><em>{usuario.username} </em></small>
                                    <Button type={'botonPeque'} setValue={handleLogOut} text="logout"/>
                                </div>
                            </div>
                        </div>
                        : ''
                    }
                </div>
                : ''
            }
            <Routes>
                <Route path="/login" element={loginPage()} />
                <Route path="/password-reset/:id/:token" element={<PasswordResetForm />} />
                <Route path="/password-reset" element={<EmailVerificationForm />}/>
                <Route path="/inventario" element={RequireAuth(<Inventario />)} />
                <Route path="/clientes" element={RequireAuth(<Clientes />)} />
                <Route path="/venta" element={RequireAuth(<Ventas />)} />
                <Route path="/usuarios" element={RequireAuth(<Usuarios />)} />
                <Route path="/reportes" element={RequireAuth(<Reportes />)} />
                <Route path="/" element={RequireAuth(<Dashboard />)}/>
            </Routes>
        </div>
    )
}

export default App
