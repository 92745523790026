import React from 'react'
import { FiEdit, FiTrash } from 'react-icons/fi'
import PropTypes from 'prop-types'

export const Producto = (props) => {
  const { nombre, descripcion, inventario, precioVenta, imagen } = props.object
  return (
    <div className="productoInventario">
      <h4>{nombre}</h4>
      <img src={imagen} alt={nombre} width="140" height="100"/>
      <div className="descripcion">{descripcion}</div>
      <div>Cant. {inventario}</div>
      <div>$ {precioVenta}</div>
      <div>
        <button className='botonProdInventario' onClick={props.actionEditar}>editar</button>
        <button className='botonProdInventario' onClick={props.actionEliminar}>eliminar</button>
        <button className='botonProdInventarioPeque' onClick={props.actionEditar}><i><FiEdit/></i></button>
        <button className='botonProdInventarioPeque' onClick={props.actionEliminar}><i><FiTrash/></i></button>
      </div>
    </div>
  )
}

Producto.propTypes = {
  type: PropTypes.string,
  object: PropTypes.object,
  actionEditar: PropTypes.func,
  actionEliminar: PropTypes.func
}

export default Producto
