export const formClick = (info) => {
  const commonOptions = {
    agregar: 'agregar',
    editar: 'editar',
    cancelar: 'cancelar',
    agregarMult: 'agregarMult',
    confirmarVenta: 'confirmarVenta',
    agregarCliente: 'agregarCliente',
    editarCliente: 'editarCliente'
  }
  return {
    [commonOptions[info.tipo]]: {
      showForm: info.tipo,
      newClient: info.datos,
      message: { value: '', type: '' }
    },
    eliminar: {
      showForm: info.tipo,
      newClient: info.datos,
      message: {
        value: `¿Desea eliminar: ${info.datos.nombre}?`,
        type: 'delete'
      }
    }
  }
}
