import React, { useState, useEffect } from 'react'
import { Filter } from './components/Filter.js'
import { Paginator } from './components/Paginator.js'
import { Categorias } from './components/Categorias.js'
import { CrudForm } from './components/CrudForm.js'
import { Notificacion } from './components/notifications/Notificacion.js'
import ProductoMultForm from './components/ProductoMultForm.js'
import { Button } from './components/Button.js'
import { Producto } from './components/Producto.js'
import {
  filterFunction,
  sortData,
  dataToSet,
  duplicateObj
} from './components/filterFunction.js'
import repoInterface from './repositories/repoInterface'
import { formClick } from './components/forms/utils.js'
import { crud } from './functions/crud.js'
import { duplicateMessage } from './components/notifications/utils.js'

const Inventario = () => {
  const productoInit = {
    nombre: '',
    codigo: '',
    descripcion: '',
    inventario: '',
    costo: '',
    precioVenta: '',
    categoria: '',
    imagen: 'img/not_available.png',
    activo: true
  }

  const formProps = {
    nombre: { text: 'Nombre', type: 'text' },
    codigo: { text: 'Código', type: 'text' },
    descripcion: { text: 'Descripción', type: 'text' },
    inventario: { text: 'Inventario', type: 'number' },
    costo: { text: 'Costo', type: 'number' },
    precioVenta: { text: 'Precio de venta', type: 'number' },
    categoria: { text: 'Categoría', type: 'text' },
    imagen: { text: 'Imagen', type: 'text' }
  }

  const productoRequired = {
    nombre: 'nombre',
    codigo: 'codigo',
    descripcion: 'descripcion',
    inventario: 'inventario',
    costo: 'costo',
    precioVenta: 'precio_venta',
    categoria: 'categoria',
    imagen: 'imagen'
  }

  const productoUnique = {
    nombre: 'nombre',
    codigo: 'codigo'
  }

  const [productos, setProductos] = useState([])
  const [filter, setFilter] = useState([])
  const [newProduct, setNewProduct] = useState(productoInit)
  const [showForm, setShowForm] = useState('')
  const [message, setMessage] = useState({ value: '', type: '' })
  const [formFilter, setFormFilter] = useState([])
  // const [ itemsToReg, setItemsToReg ] = useState({good: [], repeate: [], activate: [], error: []})

  useEffect(() => {
    repoInterface.getAll('producto')
      .then(data => {
        setProductos(data.sort(sortData))
        setFilter(data
          .filter(cli => cli.activo)
          .filter(cli => cli.inventario > 0)
          .sort(sortData))
      })
  }, [])

  const handleClickForm = (info) => {
    const values = formClick(info)
    setShowForm(values[info.tipo].showForm)
    setNewProduct(values[info.tipo].newClient)
    setMessage(values[info.tipo].message)
  }

  const handleSearch = (filterObject) => {
    const newArray = filterFunction(filterObject.value)
      .isActive(filterFunction(filterObject.value)
        .hasValue(productos))
    setFilter(newArray)
  }

  const handleSearchForm = (filterObject) => {
    const newArray = filterFunction(filterObject.value)
      .hasValue(productos)
    setFormFilter(newArray)
  }

  const handleCategorias = (cateObject) => {
    const categoriaFilter = (producto) => (producto.categoria.toLowerCase().startsWith(cateObject.value.toLowerCase()))

    const resultArray = productos.filter(prod => {
      if (prod.activo === false) return false
      if (cateObject.value === 'Todos') return true
      return categoriaFilter(prod)
    })
    setFilter(resultArray)
  }

  const manageMessage = (messageObj) => ({
    ephemeral: () => {
      setMessage(messageObj)
      setTimeout(() => {
        setMessage({ value: '', type: '' })
      }, 3000)
    },
    confirmation: () => {
      setMessage(messageObj)
    }
  })

  const actionStateChange = () => ({
    success: (dataToSet, setNewObject, messageObj) => {
      setProductos(dataToSet)
      setFilter(dataToSet)
      setNewProduct(setNewObject)
      setShowForm('')
      manageMessage(messageObj).ephemeral()
    },
    error: (messageObj) => {
      setShowForm('')
      manageMessage(messageObj).ephemeral()
    },
    duplicate: (messageObj) => {
      setShowForm('')
      manageMessage(messageObj).confirmation()
    }
  })

  const crudActions = crud(dataToSet, repoInterface, actionStateChange)

  const addcliente = (clienteObject) => {
    const duplicates = duplicateMessage(
      duplicateObj(clienteObject, productoUnique, productos))
    if (duplicates.length === 0) {
      crudActions.add(clienteObject, productoInit, filter, 'producto')
    } else {
      actionStateChange().duplicate({
        value: duplicates, type: 'repeated'
      })
    }
  }

  const editcliente = (clienteObject) => {
    crudActions.edit(clienteObject, productoInit, productos, filter, 'producto')
  }

  const deleteProducto = (id) => {
    crudActions.eliminate(id, productoInit, productos, filter, 'producto')
  }

  const prodMultForm = (prodMultObject) => {
    setFilter(prodMultObject.resultarray)
    setProductos(prodMultObject.resultarray)
    setShowForm('')
    setMessage(
      {
        value: `Se añadieron ${prodMultObject.registered.length} productos. Se econtraron ${prodMultObject.duplicates.length} duplicados, se actualizaron ${prodMultObject.edited.length}, ${prodMultObject.error.length} presentaron problemas de registro`,
        type: 'success'
      }
    )
    setTimeout(() => {
      setMessage({ value: '', type: '' })
    }, 8000)
  }

  const actions = {
    agregar: addcliente,
    editar: editcliente
  }

  return (
    <div>
      <Filter
        handleChangeFilter={(filterObject) => handleSearch(filterObject)}
        data={productos}
        type={'producto'}
      />
      <Categorias
        handleChangeCategoria={(cateObject) => handleCategorias(cateObject)}
        data={productos}
      />
      <section>
      <Notificacion
        {...message}
        actionEditar={() => handleClickForm({ tipo: 'editar', datos: newProduct })}
        actionActivar={() => handleClickForm({ tipo: 'activar', datos: newProduct })}
        actionEliminar={() => deleteProducto(newProduct.id)}
        actionCancelar={() => handleClickForm({ tipo: 'cancelar', datos: '' })}
      />
      <div className="opcionesVistaSuperior">
        <Button setValue={() => handleClickForm({ tipo: 'agregar', datos: productoInit })} type={'botonPrincipalSuperior'} text='Nuevo producto' />
        <Button setValue={() => handleClickForm({ tipo: 'agregarMult', datos: productoInit })} type={'botonPrincipalSuperior'} text='Carga multiple' />
        <Button type={'botonPrincipalSuperiorPeque'} setValue={() => handleClickForm({ tipo: 'agregar', datos: productoInit })} text={'+'} />
      </div>
      <div className='contenedorInventario'>
      <Paginator
        type={'producto'}
        productos={productos}
        filteredData={filter}
        objSchema={productoInit}
        page={1}
        editarObj={(editObject) => (handleClickForm(editObject))}
        eliminarObj={(eliminarObject) => (handleClickForm(eliminarObject))}
        vista={'productosInventario'}
      >
          <Producto />
      </Paginator>
      </div>
      </section>
      {showForm in actions
        ? <CrudForm
            type={'producto'}
            title={showForm}
            setForm={actions[showForm]}
            setNewObject={setNewProduct}
            obj={newProduct}
            uniqueProperties={productoUnique}
            reqPropperties={productoRequired}
            formProperties={formProps}
            filterInfo={formFilter}
            clickFilterList={(formObject) => handleClickForm(formObject)}
            formFilter={(filterObject) => handleSearchForm(filterObject)}
            actionCancelar={() => handleClickForm({ tipo: 'cancelar', datos: productoInit })}
          />
        : showForm === 'agregarMult' &&
          <ProductoMultForm
            title='Carga multiple'
            setForm={(prodMultObject) => prodMultForm(prodMultObject)}
            productos={productos}
            actionCancelar={() => handleClickForm({ tipo: 'cancelar', datos: productoInit })}
          />
      }
    </div>
  )
}

export default Inventario
