import React, { useState } from 'react'
import { FiXCircle } from 'react-icons/fi'
import PropTypes from 'prop-types'

export const Usuario = (props) => {
  const { nombre, username, rol, telefono, direccion } = props.object

  const [botonDetalle, setBotonDetalle] = useState(false)
  return (
    props.type === 'grande'
      ? <tr>
        <td>{nombre}</td>
        <td>{username}</td>
        <td>{rol}</td>
        <td>{telefono}</td>
        <td>{direccion.ciudad}</td>
        <td>{direccion.dir}</td>
        <td>
          <button className='botonGeneral' onClick={props.actionEditar}>editar</button>
          <button className='botonGeneral' onClick={props.actionEliminar}>eliminar</button>
        </td>
      </tr>
      : <div>
      <li onClick={() => setBotonDetalle(true)} key={telefono}>{nombre} - {username}</li>
      {botonDetalle === true &&
        <div className="ventanaEmergente">
          <div className="formulario">
            <button className='botonCerrar' onClick={() => setBotonDetalle(false)}><i><FiXCircle/></i></button>
            <table>
              <tbody>
                <tr>
                  <td>nombre:</td>
                  <td>{nombre}</td>
                </tr>
                <tr>
                  <td>usuario:</td>
                  <td>{username}</td>
                </tr>
                <tr>
                  <td>role:</td>
                  <td>{rol}</td>
                </tr>
                <tr>
                  <td>télefono:</td>
                  <td>{telefono}</td>
                </tr>
                <tr>
                  <td>ciudad:</td>
                  <td>{direccion.ciudad}</td>
                </tr>
                <tr>
                  <td>dirección:</td>
                  <td>{direccion.dir}</td>
                </tr>
              </tbody>
            </table>
            <div>
              <button className='botonGeneral' onClick={props.actionEditar}>editar</button>
              <button className='botonGeneral' onClick={props.actionEliminar}>eliminar</button>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default Usuario

Usuario.propTypes = {
  type: PropTypes.string,
  object: PropTypes.object,
  actionEditar: PropTypes.func,
  actionEliminar: PropTypes.func
}
