import React, { useState } from 'react';

export const DevolucionForm = ({
    info,
    actionAnular,
    actionCancelar
}) => {

    const [ devolucion, setDevolucion ] = useState({
            productos: [],
            cliente: '', 
            dineroDevuelto: 
            {
                metodo: info.datos.pago.metodo, 
                valor: 0
            }, 
            ventaOriginal: ''
        })


    const handleCantidadDevolucion = (prod, event) => {
        event.preventDefault()
        const productoToModify = devolucion.productos.find(n => n.productoId.id === prod.productoId.id)
        const productoOriginal = info.datos.productos.find(n => n.productoId.id === prod.productoId.id)
        const precio = productoOriginal.subtotal / productoOriginal.cantidad
        const subtotal = event.target.value > productoOriginal.cantidad ? 
        productoOriginal.cantidad * precio : event.target.value * precio        
        const modifiedProd = {
            ...productoToModify, 
            cantidad: event.target.value > productoOriginal.cantidad ? 
            productoOriginal.cantidad : event.target.value,
            subtotal: subtotal,            
        }   
        const newProdcutos = devolucion.productos
        .map(p => 
            p.productoId.id !== modifiedProd.productoId.id ? 
            p : modifiedProd)

        const newTotal = total(newProdcutos)

        setDevolucion(
            {
                ...devolucion, 
                productos: newProdcutos,
                dineroDevuelto: { 
                    ...devolucion.dineroDevuelto, 
                    valor: newTotal
                }
            },                
        )
      }

    const handleClickProd = (prod) => {
        const prodsDevolucion = devolucion.productos
        if (prodsDevolucion.filter(p => p.productoId.id === prod.productoId.id).length === 0)
        prodsDevolucion.push(prod)

        const totalDevol = total(prodsDevolucion)
        setDevolucion({...devolucion, productos: prodsDevolucion, dineroDevuelto: {...devolucion.dineroDevuelto, valor: totalDevol}})
    }
     
    const total = (arraySubtotal) => {
        const arrayTotal = []
        arraySubtotal.map((p) => (
            arrayTotal.push(p.subtotal)
        ))

        return arrayTotal.reduce((a,b) => a + b)
    }

    const funcionAnular = () => {
        const objetoAnular = {
            devolucion: {
                productos: devolucion.productos
                    .map(prod => ({
                    productoId: prod.productoId.id,
                    cantidad: prod.cantidad,
                    subtotal: prod.subtotal
                    }))
                    .filter(prod => prod.cantidad > 0 ? true : false),
                cliente: info.datos.cliente.id,
                dineroDevuelto: devolucion.dineroDevuelto,
                ventaOriginal: info.datos.id
            },
            nuevaVenta: {
                productos: info.datos.productos
                    .map(prod => {
                        const prodDevol = devolucion.productos.find(n => n.productoId.id === prod.productoId.id)
                        const precio = prod.subtotal / prod.cantidad
                        const cantidad = prodDevol ? prod.cantidad - prodDevol.cantidad : prod.cantidad
                        return {
                            productoId: prod.productoId.id,
                            cantidad: cantidad,
                            subtotal: precio * cantidad
                        }
                    })
                    .filter(prod => prod.cantidad > 0 ? true : false),
                cliente: info.datos.cliente.id,
                pago: {
                    metodo: info.datos.pago.metodo,
                    valor: info.datos.total - devolucion.dineroDevuelto.valor
                },
                total: info.datos.total - devolucion.dineroDevuelto.valor,
                ventaOriginal: info.datos.id
            }
        }

        actionAnular(objetoAnular)
    }

    return(
        <div>
            <h2>Devolución</h2>
            <p>Cliente: {info.datos.cliente.nombre} {info.datos.cliente.identificacion}</p>
            <p>Seleccione los productos</p>
            <table>
                <tbody>
                {
                    info.datos.productos.map((prod) =>  {

                        return (
                        <tr key={prod.productoId.id} onClick={() => handleClickProd(prod)}>
                            <td>{prod.productoId.nombre}</td>
                            <td>{prod.cantidad}</td>
                        </tr>   
                        )     
                    }
                    )
                }
                </tbody>       
            </table>
            <p>Ingrese la cantidad</p>
            {
                devolucion.productos.length > 0 ?
                (
                    <div>
                        <table>
                            <tbody>
                            {
                                devolucion.productos.map((prod) => {                                                                    

                                    return (
                                        <tr key={prod.productoId.id}>
                                            <td>
                                            {prod.productoId.nombre}
                                            </td>
                                            <td>
                                            <input 
                                                onChange={(event) => handleCantidadDevolucion(prod, event)} 
                                                value={prod.cantidad}
                                                placeholder='cantidad'
                                                size={5}
                                            />
                                            </td>
                                            <td>
                                            $ {prod.subtotal}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                        <p>Total: $ {devolucion.dineroDevuelto.valor}</p>                                                
                    </div>
                ) : <div>No hay devolución que registrar.</div>
            }
            {
                devolucion.productos.length > 0 && devolucion.dineroDevuelto.valor > 0 ?
                <div>
                    <button className='botonGeneral' onClick={funcionAnular}>aceptar</button>
                    <button className='botonGeneral' onClick={actionCancelar}>cancelar</button>
                </div>
                :
                <div>
                    <button className='botonGeneral' onClick={actionCancelar}>cancelar</button>
                </div>
            }
            
        </div>
    )
}

export default DevolucionForm