import React, { useState, useEffect } from 'react'
import { Filter } from './components/Filter.js'
import { Paginator } from './components/Paginator.js'
import { CrudForm } from './components/CrudForm.js'
import { Notificacion } from './components/notifications/Notificacion.js'
import { Button } from './components/Button.js'
import { Cliente } from './components/Cliente.js'
import {
  filterFunction,
  sortData,
  dataToSet,
  duplicateObj
} from './components/filterFunction.js'
import repoInterface from './repositories/repoInterface'
import { formClick } from './components/forms/utils.js'
import { crud } from './functions/crud.js'
import { duplicateMessage } from './components/notifications/utils.js'

const Clientes = () => {
  const clienteInit = {
    nombre: '',
    identificacion: '',
    telefono: '',
    correo: '',
    direccion: {
      departamento: '',
      ciudad: '',
      direccion: ''
    },
    activo: true
  }

  const formProps = {
    nombre: { text: 'Nombre', type: 'text' },
    identificacion: { text: 'Identificación', type: 'text' },
    telefono: { text: 'Teléfono', type: 'text' },
    correo: { text: 'Correo', type: 'email' },
    direccion: { text: 'Dirección', type: 'text' },
    ciudad: { text: 'Ciudad', type: 'text' },
    departamento: { text: 'Departamento', type: 'text' }

  }

  const clienteRequired = {
    nombre: 'nombre',
    identificacion: 'identificacion',
    telefono: 'telefono',
    correo: 'correo'
  }

  const clienteUnique = {
    nombre: 'nombre',
    identificacion: 'identificacion',
    telefono: 'telefono',
    correo: 'correo'
  }

  const [clientes, setClientes] = useState([])
  const [filter, setFilter] = useState([])
  const [newCliente, setNewCliente] = useState(clienteInit)
  const [showForm, setShowForm] = useState('')
  const [message, setMessage] = useState({ value: '', type: '' })
  const [formFilter, setFormFilter] = useState([])

  useEffect(() => {
    repoInterface.getAll('cliente')
      .then(data => {
        setClientes(data.sort(sortData))
        setFilter(data
          .filter(cli => cli.activo)
          .sort(sortData))
      })
  }, [])

  const handleSearch = (filterObject) => {
    const newArray = filterFunction(filterObject.value)
      .isActive(filterFunction(filterObject.value)
        .hasValue(clientes))
    setFilter(newArray)
  }

  const handleSearchForm = (filterObject) => {
    const newArray = filterFunction(filterObject.value)
      .hasValue(clientes)
    setFormFilter(newArray)
  }

  const handleClickForm = (info) => {
    const values = formClick(info)
    setShowForm(values[info.tipo].showForm)
    setNewCliente(values[info.tipo].newClient)
    setMessage(values[info.tipo].message)
  }

  const manageMessage = (messageObj) => ({
    ephemeral: () => {
      setMessage(messageObj)
      setTimeout(() => {
        setMessage({ value: '', type: '' })
      }, 3000)
    },
    confirmation: () => {
      setMessage(messageObj)
    }
  })

  const actionStateChange = () => ({
    success: (dataToSet, setNewObject, messageObj) => {
      setClientes(dataToSet)
      setFilter(dataToSet)
      setNewCliente(setNewObject)
      setShowForm('')
      manageMessage(messageObj).ephemeral()
    },
    error: (messageObj) => {
      setShowForm('')
      manageMessage(messageObj).ephemeral()
    },
    duplicate: (messageObj) => {
      setShowForm('')
      manageMessage(messageObj).confirmation()
    }
  })

  const crudActions = crud(dataToSet, repoInterface, actionStateChange)

  const addcliente = (clienteObject) => {
    const duplicates = duplicateMessage(
      duplicateObj(clienteObject, clienteUnique, clientes))

    if (duplicates.length === 0) {
      crudActions.add(clienteObject, clienteInit, filter, 'cliente')
    } else {
      actionStateChange().duplicate({
        value: duplicates, type: 'repeated'
      })
    }
  }

  const editcliente = (clienteObject) => {
    crudActions.edit(clienteObject, clienteInit, clientes, filter, 'cliente')
  }

  const deletecliente = (id) => {
    crudActions.eliminate(id, clienteInit, clientes, filter, 'cliente')
  }

  const actions = {
    agregar: addcliente,
    editar: editcliente
  }

  return <>
    <Filter
      handleChangeFilter={(filterObject) => handleSearch(filterObject)}
      data={clientes}
      type={'cliente'}
    />
    <section>
      <Notificacion
        {...message}
        actionEditar={() => handleClickForm({ tipo: 'editar', datos: newCliente })}
        actionActivar={() => handleClickForm({ tipo: 'editar', datos: newCliente })}
        actionEliminar={() => deletecliente(newCliente.id)}
        actionCancelar={() => handleClickForm({ tipo: 'cancelar', datos: clienteInit })}
      />
      <div className="opcionesVistaSuperior">
        <Button
          type={'botonPrincipalSuperior'}
          setValue={() => handleClickForm({ tipo: 'agregar', datos: newCliente })}
          text='Nuevo cliente'
        />
        <Button
          type={'botonPrincipalSuperiorPeque'}
          setValue={() => handleClickForm({ tipo: 'agregar', datos: clienteInit })}
          text={'+'}
        />
      </div>
      <Paginator
        type={'cliente'}
        filteredData={filter}
        objSchema={clienteInit}
        page={1}
        editarObj={(editObject) => (handleClickForm(editObject))}
        eliminarObj={(eliminarObject) => (handleClickForm(eliminarObject))}
        vista={'clientes'}
      >
          <Cliente />
      </Paginator>
    </section>
    {showForm in actions &&
      <CrudForm
        type={'cliente'}
        title={showForm}
        setForm={actions[showForm]}
        setNewObject={setNewCliente}
        obj={newCliente}
        uniqueProperties={clienteUnique}
        reqPropperties={clienteRequired}
        formProperties={formProps}
        filterInfo={formFilter}
        clickFilterList={(formObject) => handleClickForm(formObject)}
        formFilter={(filterObject) => handleSearchForm(filterObject)}
        actionCancelar={() => handleClickForm({ tipo: 'cancelar', datos: clienteInit })}
      />
    }
  </>
}

export default Clientes
