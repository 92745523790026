export const fechaFormatoLocal = (fecha) => {
  const diasMes = () => {
    const bisiesto = fecha.split('T')[0].split('-')[0] % 4 === 0 ? 29 : 28

    return (
      {
        1: { dias: 31, nombre: 'enero' },
        2: { dias: bisiesto, nombre: 'febrero' },
        3: { dias: 31, nombre: 'marzo' },
        4: { dias: 30, nombre: 'abril' },
        5: { dias: 31, nombre: 'mayo' },
        6: { dias: 30, nombre: 'junio' },
        7: { dias: 31, nombre: 'julio' },
        8: { dias: 31, nombre: 'agosto' },
        9: { dias: 30, nombre: 'septiembre' },
        10: { dias: 31, nombre: 'octubre' },
        11: { dias: 30, nombre: 'noviembre' },
        12: { dias: 31, nombre: 'diciembre' }
      }
    )
  }

  const horaVenta = fecha.split('T')[1].split(':')[0] - 5
  const minutosVenta = fecha.split('T')[1].split(':')[1]
  const diaVenta = fecha.split('T')[0].split('-')[2]
  const mesVenta = fecha.split('T')[0].split('-')[1]
  const yearVenta = fecha.split('T')[0].split('-')[0]

  const formatoString = (value) => (
    value > 9 ? value : '0' + value
  )

  return {
    hora: horaVenta > 0 ? horaVenta + ':' + minutosVenta : 24 + horaVenta + ':' + minutosVenta,
    dia: horaVenta > 0 ? diaVenta : diaVenta - 1 > 0 ? formatoString(diaVenta - 1) : formatoString(diaVenta - 1 + diasMes()[mesVenta - 1].dias),
    mes: horaVenta > 0 ? mesVenta : diaVenta - 1 > 0 ? mesVenta : mesVenta - 1 > 0 ? formatoString(mesVenta - 1) : 12,
    year: horaVenta > 0 ? yearVenta : diaVenta - 1 > 0 ? yearVenta : mesVenta - 1 > 0 ? yearVenta : yearVenta - 1
  }
}

export default fechaFormatoLocal
