import fechaFormatoLocal from "../functions/fechaFormatoLocal"

export const DetalleVenta = ({venta, cancelar, tipoReporte}) => {

    const fechaFormateada = fechaFormatoLocal(venta.fecha)
    return (
        <div>
            <div>               
                <strong>Fecha: {`${fechaFormateada.dia}-${fechaFormateada.mes}-${fechaFormateada.year} ${fechaFormateada.hora}`}</strong>     
                <p>id: {venta.id}</p>
            </div>                    
                <p>
                    Usuario: {venta.usuario.username}
                </p>
                <p>
                    Cliente: {venta.cliente.nombre} {venta.cliente.identificacion}
                </p>
                <table>
                <tbody>
                    <tr>
                        <td>producto</td>
                        <td>cantidad</td>
                        <td>subtotal</td>                            
                    </tr>
                    {
                        venta.productos.map((prod) => (                            
                            <tr key={prod.productoId.id}>
                                <td>{prod.productoId.nombre}</td>
                                <td>{prod.cantidad}</td>
                                <td>{prod.subtotal}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <br></br>
            <div className="total">
                <p>
                    Metodo de pago: {venta.pago.metodo}
                </p>
                <p>
                    Total: {venta.total}
                </p>
            </div>
            <br></br> 
            <div className='accionesForm'>
                {
                    tipoReporte === 'Devolucion' ? 
                    <div></div> :
                    <button className="botonGeneral" onClick={cancelar}>cerrar</button>
                }                            
            </div>
        </div>
    )
}

export default DetalleVenta