import React from 'react'
import { FilaReporteDetalleVenta } from './FilaReporteDetalleVenta'
import { FilaReporteDetalleVentaPeque } from './FilaReporteDetalleVentaPeque'
import { FilaReporteDevoluciones } from './FilaReporteDevoluciones'
import { FilaReporteDevolucionesPeque } from './FilaReporteDevolucionesPeque'
import { Paginator } from './Paginator'
import PropTypes from 'prop-types'

export const ReporteDetalleVenta = ({
  reportToShow,
  actionDetalle,
  actionAnular
}) => {
  const handleClickDetalle = (d) => {
    actionDetalle(d)
  }

  const handleClickAnular = (d) => {
    actionAnular(d)
  }

  const tipoDeFila = () => {
    const opciones = {
      'Detalle ventas': { grande: <FilaReporteDetalleVenta />, pequeno: <FilaReporteDetalleVentaPeque /> },
      Devoluciones: { grande: <FilaReporteDevoluciones />, pequeno: <FilaReporteDevolucionesPeque /> }
    }

    return opciones[reportToShow.type]
  }

  const tipoReporte = () => {
    const opciones = {
      'Detalle ventas': 'venta',
      Devoluciones: 'devoluciones'
    }

    return opciones[reportToShow.type]
  }

  return (
      <Paginator
        type={tipoReporte()}
        filteredData={reportToShow.filteredData}
        page={1}
        vista={'reporteDetalleVentas'}
        detalleObjeto={(d) => handleClickDetalle(d)}
        anularObjeto={(d) => handleClickAnular(d)}
      >
          {tipoDeFila().grande}
      </Paginator>
  )
}

ReporteDetalleVenta.propTypes = {
  type: PropTypes.string,
  reportToShow: PropTypes.object,
  actionDetalle: PropTypes.func,
  actionAnular: PropTypes.func
}

export default ReporteDetalleVenta
