export const searchFunction = (data, object, type) => {

    switch (type) {
    case 'producto':
        const nombreProd = data.find(d => d.nombre.toLowerCase() === object.nombre.toLowerCase())
        const codigoProd = data.find(d => d.codigo === object.codigo)
        
        const productoRes = {
            nombre: nombreProd ? nombreProd.nombre : false,
            codigo: codigoProd ? codigoProd.codigo : false
        }

        return productoRes
    case 'cliente':
        const nombreClie = data.find(d => d.nombre.toLowerCase() === object.nombre.toLowerCase())
        const correoClie = data.find(d => d.correo === object.correo)
        const identClie = data.find(d => d.identificacion === object.identificacion)
        const telCliente = data.find(d => d.telefono === object.telefono)

        
        const clienteRes = {
            'nombre': nombreClie ? nombreClie.nombre : false,
            'correo': correoClie ? correoClie.correo : false,
            'identificacion': identClie ? identClie.identificacion : false,
            'telefono': telCliente ? telCliente.telefono : false
        }
        console.log(clienteRes)

        return clienteRes
    case 'usuario':
        const nombreUsuario = data.find(d => d.nombre.toLowerCase() === object.nombre.toLowerCase())
        const userName = data.find(d => d.username === object.username)
        const identUser = data.find(d => d.identificacion === object.identificacion)
        const telUser = data.find(d => d.telefono === object.telefono)
        
        const usuarioRes = {
            nombre: nombreUsuario,
            username: userName,
            identificacion: identUser,
            telefono: telUser
        }

        return usuarioRes
    default:
    }
}

export default searchFunction