export const setProperty = (obj, path, value) => {
  const [head, ...rest] = path.split('.')

  return {
    ...obj,
    [head]: rest.length
      ? setProperty(obj[head], rest.join('.'), value)
      : value
  }
}

export const propsToShow = (obj, objModel) => {
  const propsToShow = {}
  Object.keys(objModel).forEach(key => {
    if (typeof obj[key] === 'object') {
      Object.keys(obj[key]).forEach((k) => {
        propsToShow[k] = {
          title: objModel[k].text,
          path: key.concat('.', k),
          value: obj[key][k],
          type: objModel[key].type
        }
      })
    }
    if (!propsToShow[key]) {
      propsToShow[key] = {
        title: objModel[key].text,
        path: key,
        value: obj[key],
        type: objModel[key].type
      }
    }
  })
  return propsToShow
}

export const eliminarDiacriticos = (texto) => {
  return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const primeraLetraMayuscula = (cadena) => {
  const primerCaracter = cadena.charAt(0).toUpperCase()
  const restoDeLaCadena = cadena.substring(1, cadena.length)
  return primerCaracter.concat(restoDeLaCadena)
}
