import React, { useState, useEffect } from 'react'
import ventasService from './services/ventas'
import devolucionesService from './services/devoluciones'
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line, BarChart, Bar, ResponsiveContainer } from 'recharts'
import PropTypes from 'prop-types'

const Dashboard = () => {
  const [ventas, setVentas] = useState([])
  const [devoluciones, setDevoluciones] = useState([])

  const fechaActual = new Date()
  const fechaActualFormato = {
    hh: fechaActual.getHours(),
    dd: fechaActual.getDate() < 10 ? '0' + fechaActual.getDate() : fechaActual.getDate().toString(),
    dia: fechaActual.getDay(),
    mm: fechaActual.getMonth() + 1 < 10 ? '0' + (fechaActual.getMonth() + 1) : (fechaActual.getMonth() + 1).toString(),
    yyyy: fechaActual.getFullYear().toString(),
    mmprev: fechaActual.getMonth() < 10 ? '0' + fechaActual.getMonth() : fechaActual.getMonth().toString()
  }

  const diasMes = () => {
    const bisiesto = fechaActualFormato.yyyy % 4 === 0 ? 29 : 28
    return (
      [
        { dias: 31, nombre: 'enero' },
        { dias: bisiesto, nombre: 'febrero' },
        { dias: 31, nombre: 'marzo' },
        { dias: 30, nombre: 'abril' },
        { dias: 31, nombre: 'mayo' },
        { dias: 30, nombre: 'junio' },
        { dias: 31, nombre: 'julio' },
        { dias: 31, nombre: 'agosto' },
        { dias: 30, nombre: 'septiembre' },
        { dias: 31, nombre: 'octubre' },
        { dias: 30, nombre: 'noviembre' },
        { dias: 31, nombre: 'diciembre' }
      ]
    )
  }

  const diasSemana = [
    'Dom',
    'Lun',
    'Mar',
    'Mie',
    'Jue',
    'Vie',
    'Sab'
  ]

  const diasPasados = {
    Dom: 0,
    Lun: 1,
    Mar: 2,
    Mie: 3,
    Jue: 4,
    Vie: 5,
    Sab: 6
  }

  useEffect(() => {
    ventasService
      .getAll()
      .then(initialProducts => {
        setVentas(initialProducts)
      })
  }, [])

  useEffect(() => {
    devolucionesService
      .getAll()
      .then(initialProducts => {
        setDevoluciones(initialProducts)
      })
  }, [])

  const formatoString = (value) => (
    value > 9 ? value.toString() : '0' + value.toString()
  )

  const ventaArray = ventas
    .filter(v => !v.devolucion || !v.devolucion.state)
    .map(v => {
      const horaVenta = v.fecha.split('T')[1].split(':')[0] - 5
      const diaVenta = v.fecha.split('T')[0].split('-')[2]
      const mesVenta = v.fecha.split('T')[0].split('-')[1]
      const yearVenta = v.fecha.split('T')[0].split('-')[0]
      const fechaVenta = {
        dd: horaVenta > 0 ? formatoString(diaVenta) : diaVenta - 1 > 0 ? formatoString(diaVenta - 1) : formatoString((diaVenta - 1) + diasMes()[parseInt(fechaActualFormato.mmprev)].dias),
        mm: horaVenta > 0 ? mesVenta : diaVenta - 1 > 0 ? mesVenta : mesVenta - 1 > 0 ? formatoString(mesVenta - 1) : 12,
        yyyy: horaVenta > 0 ? yearVenta : diaVenta - 1 > 0 ? yearVenta : mesVenta - 1 > 0 ? yearVenta : yearVenta - 1
      }

      return { fecha: fechaVenta, total: v.total, productos: v.productos }
    })
  const devolucionesArray = devoluciones.map(d => {
    const horaVenta = d.fecha.split('T')[1].split(':')[0] - 5
    const diaVenta = d.fecha.split('T')[0].split('-')[2]
    const mesVenta = d.fecha.split('T')[0].split('-')[1]
    const yearVenta = d.fecha.split('T')[0].split('-')[0]
    const fechaVenta = {
      dd: horaVenta > 0 ? diaVenta : diaVenta - 1 > 0 ? formatoString(diaVenta - 1) : (diaVenta - 1) + diasMes()[parseInt(fechaActualFormato.mmprev)].dias,
      mm: horaVenta > 0 ? mesVenta : diaVenta - 1 > 0 ? mesVenta : mesVenta - 1 > 0 ? formatoString(mesVenta - 1) : 12,
      yyyy: horaVenta > 0 ? yearVenta : diaVenta - 1 > 0 ? yearVenta : mesVenta - 1 > 0 ? yearVenta : yearVenta - 1
    }

    return { fecha: fechaVenta, total: d.dineroDevuelto.valor }
  })

  const puntosDiasMes = () => {
    const arrayPuntos = []
    // const arrayPuntosDevoluciones = []
    // const devolucionesMes = devolucionesArray.filter(d => d.mm === fechaActualFormato.mm)

    for (let i = 1; i <= diasMes()[parseInt(fechaActualFormato.mm)].dias; i++) {
      const dia = i < 10 ? '0' + i : i.toString()
      arrayPuntos.push(dia)
    }
    return arrayPuntos
  }

  const data = () => {
    const ventasMes = ventaArray.filter(v => v.fecha.mm === fechaActualFormato.mm && v.fecha.yyyy === fechaActualFormato.yyyy)
    const devolucionesMes = devolucionesArray.filter(d => d.fecha.mm === fechaActualFormato.mm && d.fecha.yyyy === fechaActualFormato.yyyy)
    // console.log('ventasMes', ventasMes)
    return puntosDiasMes().map(p => {
      const cantidadVentas = ventasMes.filter(v => v.fecha.dd === p)
      // console.log('cantidadVentas', cantidadVentas)
      const cantidadDevoluciones = devolucionesMes.filter(d => d.fecha.dd === p)
      if (cantidadVentas.length > 0) {
        if (cantidadDevoluciones.length > 0) {
          return {
            name: `${p} ${diasMes()[parseInt(fechaActualFormato.mm)].nombre}`,
            ventas: cantidadVentas.length,
            devoluciones: cantidadDevoluciones.length
          }
        }
        return {
          name: `${p} ${diasMes()[parseInt(fechaActualFormato.mm)].nombre}`,
          ventas: cantidadVentas.length,
          devoluciones: 0
        }
      }
      if (cantidadDevoluciones.length > 0) {
        return {
          name: `${p} ${diasMes()[parseInt(fechaActualFormato.mm)].nombre}`,
          ventas: 0,
          devoluciones: cantidadDevoluciones.length
        }
      }
      return {
        name: `${p} ${diasMes()[parseInt(fechaActualFormato.mm)].nombre}`,
        ventas: 0,
        devoluciones: 0
      }
    })
  }

  // const ventasSemanales = () => (
  //   diasSemana.map(d => {
  //     const diaActual = fecha.toString().split(' ')[0]
  //     const diaTraducido = traducirDiasSemana[diaActual]
  //     const fechaDiaSemana = fechaActualFormato.dd - (diasPasados[diaTraducido] - diasPasados[d])
  //     const fechaDiaSemanaString = fechaDiaSemana < 10 ? '0' + fechaDiaSemana : fechaDiaSemana.toString()
  //     return ventaArray.filter(v => v.fecha.dd === fechaDiaSemanaString)
  //   })

  //   console.log()
  // )

  const puntosDiasSemana = () => (
    diasSemana.map(d => {
      // const diaActual = fechaActual.toString().split(' ')[0]
      // const diaTraducido = traducirDiasSemana[fechaActualFormato.dia]
      const valorDiaSemana = fechaActualFormato.dd - (fechaActualFormato.dia - diasPasados[d])
      const fechaDiaSemana = valorDiaSemana < 1 ? diasMes()[parseInt(fechaActualFormato.mmprev)].dias + valorDiaSemana : valorDiaSemana
      const fechaDiaSemanaString = formatoString(fechaDiaSemana)
      const ventasDiarias = ventaArray
        .filter(v => v.fecha.mm === fechaActualFormato.mm)
        .filter(v => v.fecha.dd === fechaDiaSemanaString)

      return {
        name: `${d} ${fechaDiaSemanaString}`,
        ventas: ventasDiarias.length,
        fecha: fechaDiaSemanaString
      }
    })
  )

  const prodcutosVendidos = (periodo) => {
    const arrayProductos = []
    const ventasMes = ventaArray.filter(v => v.fecha.mm === fechaActualFormato.mm)
    // const ventasSemana = ventasSemanales()

    const productosPorVenta = {
      historico: ventaArray
        .map(v => v.productos.map(p => ({
          nombre: p.productoId.nombre,
          cantidad: p.cantidad
        }))),
      mes: ventasMes
        .map(v => v.productos.map(p => ({
          nombre: p.productoId.nombre,
          cantidad: p.cantidad
        })))
      // 'semana': ventasSemana
      // .map(v => v.productos.map(p => ({
      //   nombre: p.productoId.nombre,
      //   cantidad: p.cantidad
      // })))
    }

    for (const i in productosPorVenta[periodo]) {
      for (const j in productosPorVenta[periodo][i]) {
        const prodExiste = arrayProductos.find(a => a.nombre?.toLowerCase().trim() === productosPorVenta[periodo][i][j].nombre?.toLowerCase().trim())
        const indexProd = arrayProductos.indexOf(prodExiste)

        if (!prodExiste) {
          arrayProductos.push({
            nombre: productosPorVenta[periodo][i][j].nombre,
            unidades: productosPorVenta[periodo][i][j].cantidad
          })
        } else {
          arrayProductos[indexProd] = {
            ...arrayProductos[indexProd],
            unidades: arrayProductos[indexProd].unidades + productosPorVenta[periodo][i][j].cantidad
          }
        }
      }
    }

    return arrayProductos
  }

  // const prodcutosDevueltos = () => {

  //   const arrayProductos = []
  //   const productosPorVenta = devoluciones.map(v => v.productos.map(p => ({nombre: p.productoId.nombre, cantidad: p.cantidad})))

  //   for(let i in productosPorVenta) {
  //     for(let j in productosPorVenta[i]){

  //       const prodExiste = arrayProductos.find(a => a.name === productosPorVenta[i][j].nombre)
  //       const indexProd = arrayProductos.indexOf(prodExiste)

  //       if (!prodExiste) {
  //         arrayProductos.push({
  //           'name': productosPorVenta[i][j].nombre,
  //           'unidades': productosPorVenta[i][j].cantidad
  //         })
  //       } else {
  //         arrayProductos[indexProd] = {
  //           ...arrayProductos[indexProd],
  //           'unidades': arrayProductos[indexProd].unidades + productosPorVenta[i][j].cantidad
  //         }
  //       }

  //     }
  //   }

  //   return arrayProductos
  // }

  const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-30)">
          {payload.value}
        </text>
      </g>
    )
  }

  return (
    <div>
      <h1 className='tituloPagina'>POS Granada Campestre</h1>
      <div className='sectionDashBoard'>
        <div className="dashboardHorizontal">
          <div className='dashboardCard'>
            <h3>Ventas del mes</h3>
            <ResponsiveContainer width='100%' height='90%' minHeight={1} minWidth={1}>
              <LineChart data={data()}
                margin={{ top: 5, right: 30, left: 0, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" height={70} tick={<CustomizedAxisTick />}/>
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="ventas" stroke="#82ca9d" />
                <Line type="monotone" dataKey="devoluciones" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className='dashboardCard'>
          <h3>Ventas de la semana</h3>
            <ResponsiveContainer width='100%' height='90%' minHeight={1} minWidth={1}>
              <LineChart data={puntosDiasSemana()}
                margin={{ top: 5, right: 30, left: 0, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" height={40} />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="ventas" stroke="#82ca9d" />
                <Line type="monotone" dataKey="devoluciones" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className='dashboardCard'>
            <h3>Productos vendidos (historico)</h3>
            <ResponsiveContainer width='100%' height='90%' minHeight={1} minWidth={1}>
              <BarChart data={prodcutosVendidos('historico')}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="nombre" height={70} tick={<CustomizedAxisTick />}/>
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="unidades" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className='dashboardCard'>
            <h3>Productos vendidos (mes)</h3>
            <ResponsiveContainer width='100%' height='90%' minHeight={1} minWidth={1}>
              <BarChart data={prodcutosVendidos('mes')}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="nombre" height={70} tick={<CustomizedAxisTick />}/>
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="unidades" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
          <div className='dashboardVertical'>
            <div className='dashboardCardVertical'>
              <h3>Ventas del mes</h3>
              <ResponsiveContainer width='98%' height='90%' minHeight={1} minWidth={1}>
                <BarChart data={data()} layout="vertical">
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" domain={[0, 'dataMax']}/>
                  <YAxis dataKey="name" type='category'/>
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="ventas" fill="#82ca9d" />
                  <Bar dataKey="devoluciones" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div className='dashboardCardVertical'>
              <h3>Ventas de la semana</h3>
              <ResponsiveContainer width='98%' height='90%' minHeight={1} minWidth={1}>
                <BarChart data={puntosDiasSemana()} layout="vertical" >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" domain={[0, 'dataMax']}/>
                  <YAxis dataKey="name" type='category'/>
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="ventas" fill="#82ca9d" />
                  <Bar dataKey="devoluciones" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div className='dashboardCardVertical'>
            <h3>Productos vendidos (historico)</h3>
            <ResponsiveContainer width='100%' height='90%' minHeight={1} minWidth={1}>
              <BarChart data={prodcutosVendidos('historico')} layout="vertical">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" domain={[0, 'dataMax']}/>
                <YAxis dataKey="nombre" type='category' />
                <Tooltip />
                <Legend />
                <Bar dataKey="unidades" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className='dashboardCardVertical'>
            <h3>Productos vendidos (mes)</h3>
            <ResponsiveContainer width='100%' height='90%' minHeight={1} minWidth={1}>
              <BarChart data={prodcutosVendidos('mes')} layout="vertical">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" domain={[0, 'dataMax']}/>
                <YAxis dataKey="nombre" type='category'/>
                <Tooltip />
                <Legend />
                <Bar dataKey="unidades" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      </div>
  )
}

Dashboard.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.number,
  object: PropTypes.object,
  actionEditar: PropTypes.func,
  actionEliminar: PropTypes.func
}

export default Dashboard
