import { useState, useEffect } from 'react';
import ventasService from '../services/ventas';
import devolucionesService from '../services/devoluciones';
import fechaFormatoLocal from "../functions/fechaFormatoLocal"

export const VentaForm = ({
    actionCancelar,
    submitForm
}) => {
    const [ ventas, setVentas ] = useState([])
    const [ report, setReport ] = useState({type: '', initDate: '', finDate: '', data: []})
    const [ devoluciones, setDevoluciones ] = useState([])

    useEffect(() => {
        ventasService
          .getAll()
          .then(initialProducts => {
            setVentas(initialProducts)               
          })
    }, [])

    useEffect(() => {
        devolucionesService
          .getAll()
          .then(initialProducts => {
            setDevoluciones(initialProducts)               
          })
    }, [])

    const handleInputChange = (event) => {
        switch (event.target.previousSibling.data.toLowerCase().slice(0,-2)) {
            case 'tip' : 
                setReport({...report, type: event.target.value})
            break;  
            case 'fecha inicial' : 
                setReport({...report, initDate: event.target.value})
            break;
            case 'fecha final' : 
                setReport({...report, finDate: event.target.value})
            break;
            default:

        }
    }

    const handleSubmit = (event) => {
        event.preventDefault() 

        const reportToShow = () => {

            const reportType = {
                'Detalle ventas' : 
                ventas
                    .filter(venta => (
                        venta.devolucion ? false : true
                    ))
                    .filter(venta => {
                        const fechaFormateada = fechaFormatoLocal(venta.fecha)
                        const ventaComp = fechaFormateada.year + '-' + fechaFormateada.mes + '-' + fechaFormateada.dia
                        if (ventaComp >= report.initDate && ventaComp <= report.finDate) return true
                        return false
                    }),
                    'Devoluciones' :
                    devoluciones
                    .filter(venta => {
                        const fechaFormateada = fechaFormatoLocal(venta.fecha)
                        const ventaComp = fechaFormateada.year + '-' + fechaFormateada.mes + '-' + fechaFormateada.dia
                        if (ventaComp >= report.initDate && ventaComp <= report.finDate) return true
                        return false
                    })
                }
            
            return reportType[report.type]
        }

        const formObject = {
            type: report.type,
            tipoReporte: report.type,
            data: reportToShow(),
            filteredData: reportToShow(),
            dataComparacion: ventas,
            initDate: report.initDate,
            finDate: report.finDate
        }

        submitForm(formObject)
    }    

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="formInputs">
                    <div>
                        Tipo: 
                        <select onChange={handleInputChange}>
                            <option>-</option>
                            <option>Detalle ventas</option>
                            <option>Devoluciones</option>
                        </select>
                    </div>
                    <div>
                        Fecha inicial: <input type='date' onChange={handleInputChange} value={report.initDate}/>
                    </div>
                    <div>
                        Fecha final: <input type='date' onChange={handleInputChange} value={report.finDate}/>
                    </div>
                    <div className='accionesForm'> 
                        <button className='botonGeneral'>aceptar</button>
                        <button className='botonGeneral' onClick={actionCancelar}>cancelar</button>
                    
                    </div>
                </div>
            </form>            
        </div>
    )
}

export default VentaForm;