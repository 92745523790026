import axios from 'axios'

const apiUrl = process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_DEV_URL
    : process.env.REACT_APP_PROD_URL
const baseUrl = `${apiUrl}/api/login`

let token = null

const setToken = newToken => {
    token = `bearer ${newToken}`
}

const login = async credentials => {
  const response = await axios.post(baseUrl, credentials)
  return response.data
}

const verifyToken = async () => {
  const config = {
    headers: { Authorization: token }
  }

  const response = await axios.post(`${baseUrl}/verify-token`, config)
  return response.data
}

const exportedOject = { login, verifyToken, setToken }

export default exportedOject