import React, { useState } from 'react'
import { FiXCircle } from 'react-icons/fi'
import PropTypes from 'prop-types'
import { setProperty, propsToShow, eliminarDiacriticos } from '../functions/utils'

export const CrudForm = ({
  type,
  title,
  setForm,
  setNewObject,
  obj,
  uniqueProperties,
  reqPropperties,
  formProperties,
  filterInfo,
  clickFilterList,
  formFilter,
  actionCancelar
}) => {
  const initFilter = (obj) => {
    const filterObj = {}
    Object.keys(uniqueProperties).forEach(k => {
      filterObj[k] = { value: obj[k], state: false }
    })
    return filterObj
  }
  const [filter, setFilter] = useState(initFilter(obj))

  const handleCliente = event => {
    event.preventDefault()
    setForm({ ...obj })
  }

  const handleInputChange = event => {
    event.preventDefault()
    const key = event.currentTarget.getAttribute('data-key')
    const path = event.currentTarget.getAttribute('data-path')

    if (key in uniqueProperties) {
      const filterObject = initFilter(obj)
      const newFilter = {
        value: event.target.value,
        state: event.target.value !== ''
      }
      const normalizedKey = eliminarDiacriticos(key)
      filterObject[normalizedKey] = newFilter
      setFilter(filterObject)
      formFilter(newFilter)
    }
    setNewObject(setProperty(obj, path, event.target.value))
  }

  const handleClickCliente = (event) => {
    event.preventDefault()
    const articuloToEdit = filterInfo
      .find(cli => cli.identificacion === event.target.lastChild.data)
    const objToEdit = { ...obj, ...articuloToEdit }
    const formObj = {
      tipo: 'editar',
      datos: objToEdit
    }
    clickFilterList(formObj)
    setNewObject(formObj.datos)
    setFilter(initFilter(obj))
  }

  const mostrarAccionAceptar = (reqProps, object) => Object
    .keys(reqProps)
    .map((key) => object[key] !== '')
    .every(elem => elem)

  return (
    <div className="ventanaEmergente">
      <div className='formulario'>
        <h2>{title} {type}</h2>
        <button className='botonCerrar' onClick={actionCancelar}><i><FiXCircle/></i></button>
        <form className="formInputs">
          {Object.keys(propsToShow(obj, formProperties)).map(key => (
            <div key={key}>
              {propsToShow(obj, formProperties)[key].title}
              <input
                data-path={propsToShow(obj, formProperties)[key].path}
                data-key={key}
                type={propsToShow(obj, formProperties)[key].type}
                onChange={handleInputChange}
                value={propsToShow(obj, formProperties)[key].value}
              />
              {filter[key]?.state &&
                filterInfo.map((cliente) => (
                      <div
                        key={cliente.identificacion}
                        onClick={handleClickCliente}
                      >
                        {cliente.nombre} {cliente.identificacion}
                      </div>
                ))}
            </div>
          ))}
        </form>
        {mostrarAccionAceptar(reqPropperties, obj) &&
          <div className='accionesForm'>
            <button className='botonGeneral' onClick={handleCliente}>aceptar</button>
          </div>
        }
      </div>
    </div>
  )
}

CrudForm.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  setForm: PropTypes.func,
  setNewObject: PropTypes.func,
  obj: PropTypes.object,
  uniqueProperties: PropTypes.object,
  reqPropperties: PropTypes.object,
  formProperties: PropTypes.object,
  filterInfo: PropTypes.array,
  clickFilterList: PropTypes.func,
  formFilter: PropTypes.func,
  actionCancelar: PropTypes.func
}

export default CrudForm
