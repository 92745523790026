import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import passResetService from '../services/passwordReset'
import { Notificacion } from '../components/notifications/Notificacion.js'

const PasswordResetForm = () => {
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [message, setMessage] = useState({ value: '', type: '' })
    const { id, token } = useParams()
    const navigate = useNavigate()

    const handlePassReset = async (event) => {
        event.preventDefault()
        const request = {
            id,
            token,
            credentials: { password }
        }
        passResetService.resetPassword(request)
            .then(data => {
                setMessage(
                    {
                        value: data,
                        type: 'success'
                    }
                )
                setTimeout(() => {
                    setMessage({ value: '', type: '' })
                    navigate('/')
                }, 4000)
            })
            .catch(error => {
                setMessage(
                    {
                        value: error.response.data,
                        type: 'failure'
                    }
                )
                setTimeout(() => {
                    setMessage({ value: '', type: '' })
                }, 4000)
            })
    }

    return (
        <div>
            <Notificacion
                {...message}
            />
            <div className='passwordReset'>
                <div className='passResetForm'>
                    <h2>Cambia tu constraseña</h2>
                    <form onSubmit={handlePassReset}>
                        <div className="passReset">
                            Nueva contraseña:
                            <input
                                type="password"
                                value={password}
                                name="Password"
                                onChange={({ target }) => setPassword(target.value)}
                            />
                        </div>
                        <div className="passReset">
                            Confirmar contraseña:
                            <input
                                type="password"
                                value={passwordConfirm}
                                name="PasswordConfirmation"
                                onChange={({ target }) => setPasswordConfirm(target.value)}
                            />
                        </div>
                        {password !== passwordConfirm || password === ''
                            ? <button className='botonPrincipal' type="submit" disabled>enviar</button>
                            : <button className='botonPrincipal' type="submit">enviar</button>
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}

export default PasswordResetForm
