import React, { useState } from 'react'
import { Button } from './Button.js'
import Table from 'react-bootstrap/Table'
import PropTypes from 'prop-types'

export const Paginator = (props) => {
  const {
    type,
    filteredData,
    page,
    editarObj,
    eliminarObj,
    children,
    addProdVenta,
    objSchema,
    vista,
    detalleObjeto,
    anularObjeto
  } = props

  const [pagina, setPagina] = useState(page)
  const [posicionBoton, setPosicionBoton] = useState(0)
  const columnasVistaTabla = {
    usuarios: [
      'Nombre',
      'Usuario',
      'Role',
      'Télefono',
      'Ciudad',
      'Dirección',
      'Acción'
    ],
    clientes: [
      'Nombre',
      'Identificación',
      'Télefono',
      'Correo',
      'Ciudad',
      'Dirección',
      'Acción'
    ],
    reporteDetalleVentas: [
      'id',
      'fecha',
      'total',
      'acción'
    ]
  }

  const elemsPerPags = 12
  const pags = Math.ceil(filteredData.length / elemsPerPags)
  const elemsToShow = []
  const buttons = []
  const buttonsToShow = []
  const avanzarButtons = Math.ceil(pags / 3)

  for (let i = 0; i < pags; i++) {
    const listCeil = elemsPerPags * (i + 1)
    const listInit = i * elemsPerPags
    elemsToShow.push(filteredData.slice(listInit, listCeil))
    buttons.push(i + 1)
  }

  for (let i = 0; i <= avanzarButtons; i++) {
    const ceil = (i + 1) * 3
    buttonsToShow.push(buttons.slice(i * 3, ceil))
  }

  const indexToShow = (pag) => {
    let index = pag - 1
    while (!elemsToShow[index] && index > 0) {
      index -= 1
    }
    return index
  }

  const elementProps = (elementObj, type) => {
    return React.Children
      .map(children, (child) => (
        React.cloneElement(child, {
          key: elementObj.id,
          object: elementObj,
          type,
          actionEditar: () => (handleEdit(elementObj)),
          actionEliminar: () => (handleEliminar(elementObj)),
          addProdVenta: () => (handleClickProd(elementObj)),
          accionDetalle: () => (handleDetalle(elementObj)),
          accionAnular: () => (handleAnular(elementObj))
        })
      ))
  }

  const handleEdit = (producto) => {
    return editarObj({
      tipo: 'editar',
      datos: producto
    })
  }

  const handleEliminar = (producto) => (
    eliminarObj({
      tipo: 'eliminar',
      datos: producto
    })
  )

  const handleClickProd = (producto) => (
    addProdVenta({ ...producto })
  )

  const handleDetalle = (objeto) => (
    detalleObjeto({ ...objeto })
  )

  const handleAnular = (objeto) => (
    anularObjeto({ ...objeto })
  )

  const showElements = (data, size) => data
    .map((object) => (
      elementProps({ ...objSchema, ...object }, size)
    ))

  return (
    <div className='contenido'>
      <h3>{filteredData.length} {type}(s)</h3>
      <div id={'botonPaginator'}>
        {posicionBoton > 0 &&
          <button
            className={'botonPeque'}
            onClick={() => setPosicionBoton(posicionBoton - 1)}
          >{'<'}</button>
        }
        {buttonsToShow[posicionBoton].map((button) =>
          <Button
            key={button}
            type={'botonGeneral'}
            setValue={() => setPagina(button)}
            text={button}
          />)}
        {avanzarButtons > 1 &&
          avanzarButtons >= posicionBoton * 3 &&
          <button
            className={'botonPeque'}
            onClick={() => setPosicionBoton(posicionBoton + 1)}
          >{'>'}</button>
        }
      </div>
      <div className={vista}>
      {elemsToShow.length > 0
        ? vista in columnasVistaTabla
          ? <div>
            <Table responsive striped bordered hover className='divTabla'>
              <tbody>
                <tr>
                  {columnasVistaTabla[vista].map((title) => (
                    <th key={title}>{title}</th>
                  ))}
                </tr>
                {showElements(elemsToShow[indexToShow(pagina)], 'grande')}
              </tbody>
            </Table>
            <div className='listaVistaPeque'>
              <ul>
              {showElements(elemsToShow[indexToShow(pagina)], 'peque')}
              </ul>
            </div>
          </div>
          : showElements(elemsToShow[indexToShow(pagina)], 'grande')
        : <p>No hay registros</p>
        }
      </div>
    </div>
  )
}

Paginator.propTypes = {
  type: PropTypes.string,
  filteredData: PropTypes.array,
  page: PropTypes.number,
  editarObj: PropTypes.func,
  eliminarObj: PropTypes.func,
  children: PropTypes.element,
  addProdVenta: PropTypes.func,
  objSchema: PropTypes.object,
  vista: PropTypes.string,
  detalleObjeto: PropTypes.func,
  anularObjeto: PropTypes.func
}

export default Paginator
