import fechaFormatoLocal from '../functions/fechaFormatoLocal'
import React from 'react'
import PropTypes from 'prop-types'

export const FilaReporteDetalleVenta = (props) => {
  const { id, fecha, total } = props.object

  const fechaFormateada = fechaFormatoLocal(fecha)

  return (
    props.type === 'grande'
      ? <tr key={id}>
          <td>{id}</td>
          <td>{`${fechaFormateada.dia}-${fechaFormateada.mes}-${fechaFormateada.year} ${fechaFormateada.hora}`}</td>
          <td>$ {total}</td>
          <td>
            <button className="botonGeneral" onClick={props.accionDetalle}>detalle</button>
            <button className="botonGeneral" onClick={props.accionAnular}>anular</button>
          </td>
        </tr>
      : <div style={{display: 'flex', alignItems: 'center'}}>
          <li style={{width: '70%'}} onClick={props.accionDetalle} key={id}>{`${fechaFormateada.dia}-${fechaFormateada.mes}-${fechaFormateada.year} ${fechaFormateada.hora}`} - ${total}</li>
          <button style={{height: '100%'}} className="botonGeneral" onClick={props.accionAnular}>anular</button>
        </div>
  )
}

FilaReporteDetalleVenta.propTypes = {
  type: PropTypes.string,
  object: PropTypes.object,
  actionEditar: PropTypes.func,
  actionEliminar: PropTypes.func,
  accionAnular: PropTypes.func,
  accionDetalle: PropTypes.func
}
