import React, { useState, useEffect } from 'react'
import { ReporteDetalleVenta } from './components/ReporteDetalleVenta'
import { DetalleVenta } from './components/DetalleVenta'
import { DetalleDevolucion } from './components/DetalleDevolucion'
import { Filter } from './components/Filter.js'
import { DevolucionForm } from './components/DevolucionForm'
import { Notificacion } from './components/notifications/Notificacion.js'
import { Button } from './components/Button.js'
import { filterFunction } from './components/filterFunction.js'
import { VentaForm } from './components/VentaForm'
import ventasService from './services/ventas'
import devolucionesService from './services/devoluciones'
import productosService from './services/productos'
import * as XLSX from 'xlsx'

const Reportes = () => {
  const [reportToShow, setReportToShow] = useState({ form: 'cancelar', type: '', data: [], filteredData: [], tipoReporte: '', dataComparacion: [], initDate: '', finDate: '' })
  const [detalle, setDetalle] = useState({ active: false, data: [] })
  const [message, setMessage] = useState({ value: '', type: '' })
  const reportes = ['ventas']
  const [productos, setProductos] = useState([])
  const [showForm, setShowForm] = useState('')

  useEffect(() => {
    productosService
      .getAll()
      .then(initialProducts => {
        setProductos(initialProducts)
      })
  }, [])

  const handleClickReporte = (event) => {
    event.preventDefault()
    setReportToShow({ ...reportToShow, form: event.target.innerText, type: event.target.innerText })
  }

  const submitReport = (ventasArray) => {
    setReportToShow({ ...reportToShow, ...ventasArray, form: '' })
  }

  const handleClickDetalle = (d) => {
    setDetalle({ ...detalle, active: !(detalle.active), data: d })
  }

  const handleExport = () => {
    const worksheet = XLSX.utils.json_to_sheet(ventasCleanUp(reportToShow.data))
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, "Ventas")
    XLSX.writeFile(workbook, `ventas-granadacampestre-${reportToShow.initDate}-${reportToShow.finDate}.xlsx`, { compression: true })
  }

  const prodsDevolucion = (info) => (
    <DevolucionForm
      info={info}
      actionAnular={(objetoAnular) => handleClickAnular(objetoAnular)}
      actionCancelar={() => handleClickForm({ datos: '', tipo: 'cancelar' })}
    />
  )

  const handleClickForm = (info) => {
    if (info.tipo === 'anular') {
      setDetalle({ ...detalle, data: info })
      setShowForm(info.tipo)
      setMessage({ value: '', type: 'anular' })
    } else if (info.tipo === 'cancelar') {
      setDetalle({ active: false, data: '' })
      setShowForm('')
      setMessage({ value: '', type: '' })
    }
  }

  const handleClickAnular = async (objetoAnular) => {
    try {
      const venta = reportToShow.data.find(n => n.id === objetoAnular.devolucion.ventaOriginal)
      const newVentaToAdd = objetoAnular.nuevaVenta
      const newVentaAdded = newVentaToAdd.productos.length > 0
        ? await ventasService.create(newVentaToAdd)
        : { id: '' }
      const devolucionToAdd = { ...objetoAnular.devolucion, ventaNueva: newVentaAdded.id }
      const devolucionAdded = await devolucionesService.create(devolucionToAdd)
      const ventaToEdit = { ...venta, devolucion: { state: true, id: devolucionAdded.id }, nuevaVenta: newVentaAdded.id }
      const ventaEdited = await ventasService.update(venta.id, ventaToEdit)

      for (const i in objetoAnular.devolucion.productos) {
        const prodToEdit = productos.find(n => n.id === objetoAnular.devolucion.productos[i].productoId)
        const newCantidad = parseInt(prodToEdit.inventario) + parseInt(objetoAnular.devolucion.productos[i].cantidad)
        const productoNew = { ...prodToEdit, inventario: newCantidad }
        await productosService.update(objetoAnular.devolucion.productos[i].productoId, productoNew)
      }

      newVentaAdded.id !== ''
        ? setReportToShow({
          ...reportToShow,
          data: reportToShow.data.map((d) => (
            d.id !== ventaEdited.id
              ? d
              : ventaEdited
          ))
            .concat(newVentaAdded),
          filteredData: reportToShow.filteredData.map((d) => (
            d.id !== ventaEdited.id
              ? d
              : ventaEdited
          ))
            .concat(newVentaAdded)
        })
        : setReportToShow({
          ...reportToShow,
          data: reportToShow.data.map((d) => (
            d.id !== ventaEdited.id
              ? d
              : ventaEdited
          )),
          filteredData: reportToShow.filteredData.map((d) => (
            d.id !== ventaEdited.id
              ? d
              : ventaEdited
          ))
        })

      setMessage(
        {
          value: 'Venta anulada exitosamente',
          type: 'success'
        }
      )
      setTimeout(() => {
        setMessage({ value: '', type: '' })
      }, 3000)
    } catch (error) {
      console.log(error.response.data)
      setMessage(
        {
          value: error.response.data.error,
          type: 'failure'
        }
      )
      setTimeout(() => {
        setMessage({ value: '', type: '' })
      }, 3000)
    }
  }

  const handleSearch = (filterObject) => {
    const newArray = filterFunction(reportToShow.data, filterObject, 'reporteVentas', 'hide')
    setReportToShow({ ...reportToShow, filteredData: newArray })
  }

  const detalleReportType = () => {
    const opciones = {
      'Detalle ventas':
        <DetalleVenta
          venta={detalle.data}
          cancelar={handleClickDetalle}
        />,
      Devoluciones:
        <DetalleDevolucion
          devolucion={detalle.data}
          dataComp={reportToShow.dataComparacion}
          cancelar={handleClickDetalle}
        />
    }

    return opciones[reportToShow.tipoReporte]
  }

  const ventasCleanUp = (data) =>
    data.map(d => (
      {
        cliente: d.cliente.identificacion,
        fecha: d.fecha,
        id: d.id,
        pago: d.pago.metodo,
        productos: d.productos.map(p => p.productoId.nombre).join('-'),
        total: d.total,
        usuario: d.usuario.username
      }
    ))

  return (
    <div>
      {
        reportToShow.form === 'cancelar' || reportToShow.form === 'X'
          ? <div className="ventanaEmergente">
            <div className='confirmarAccion'>
                  {
                    reportes.map(rep => (
                      <div key={rep}>
                        <Button type={'botonPrincipal'} setValue={handleClickReporte} text={rep}/>
                      </div>
                    ))
                  }
            </div>
          </div>
          : reportToShow.form === 'ventas'
            ? <div className="ventanaEmergente">
              <div className='confirmarAccion'>
              <VentaForm
                submitForm={submitReport}
                actionCancelar={handleClickReporte}
              />
            </div>
          </div>
            : detalle.active === true
              ? <div className="ventanaEmergente">
              <div className='confirmarAccion'>
                {detalleReportType()}
              </div>
            </div>
              : ''
      }
      {
        reportToShow.type === 'Detalle ventas'
          ? <div>
          <Filter
            handleChangeFilter={(filterObject) => handleSearch(filterObject)}
            data={reportToShow.data}
            type={'reporteVentas'}
          />
          <section>
            <Notificacion {...message} />
            <div className="opcionesVistaSuperior">
              {reportToShow.data.length > 0 && <Button type={'botonPrincipalSuperior'} setValue={handleExport} text='exportar' />}
              <Button type={'botonPrincipalSuperior'} setValue={handleClickReporte} text='cancelar' />
              {reportToShow.data.length > 0 && <Button type={'botonSuperiorExportar'} setValue={handleExport} text='exportar' />}
              <Button type={'botonSuperiorCerrar'} setValue={handleClickReporte} text='X' />
            </div>
            <ReporteDetalleVenta
              reportToShow={reportToShow}
              actionDetalle={(d) => handleClickDetalle(d)}
              actionAnular={(d) => handleClickForm({ datos: d, tipo: 'anular' })}
            />
          </section>
          </div>
          : reportToShow.type === 'Devoluciones'
            ? <div>
      <Filter
        handleChangeFilter={(filterObject) => handleSearch(filterObject)}
        data={reportToShow.data}
        type={'reporteVentas'}
      />
      <section>
        <Notificacion
          {...message}
          actionEliminar={handleClickAnular}
          actionCancelar={() => handleClickForm({ datos: '', tipo: 'cancelar' })}
        />
        <div className="opcionesVistaSuperior">
            <Button type={'botonPrincipalSuperior'} setValue={handleClickReporte} text='exportar' />
            <Button type={'botonPrincipalSuperior'} setValue={handleClickReporte} text='cancelar' />
            <Button type={'botonSuperiorExportar'} setValue={handleClickReporte} text='exportar' />
            <Button type={'botonSuperiorCerrar'} setValue={handleClickReporte} text='X' />
          </div>
        <ReporteDetalleVenta
          reportToShow={reportToShow}
          actionDetalle={(d) => handleClickDetalle(d)}
          actionAnular={(d) => handleClickForm({ datos: d, tipo: 'anular' })}
        />
      </section>
      </div>
            : ''
      }
      {showForm === 'anular' &&
        <div className="ventanaEmergente">
          <div className='formulario'>{prodsDevolucion(detalle.data)}</div>
        </div>}
      </div>
  )
}

export default Reportes
