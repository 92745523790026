import { useState } from 'react';

export const ClienteRegRapido = ({
    title,
    setForm,
    cliente,
    handleFormFilter,
    infoFormFilter,
    actionCancelar,
    handleClickFormFilter
}) => {

    const [ newCliente, setNewCliente ] = useState(cliente)
    const [ nombreFilter, setNombreFilter ] = useState({value: cliente.nombre, state: false})
    const [ identFilter, setIdentFilter ] = useState({value: cliente.identificacion, state: false})

    const handleCliente = (event) => {
        event.preventDefault()
        setForm({...newCliente})
    }

    const handleChangeFilter = (event) => {
        event.preventDefault();
        var state = false
  
        if (event.target.value === '') {state = false}
        else{state = true}
  
        const newFilter = {
        value: event.target.value,
        state: state
        }
  
        switch (event.target.previousSibling.data.toLowerCase().slice(0,-2)){
          case 'nombre':
            setNombreFilter(newFilter)
            setNewCliente({...newCliente, nombre: event.target.value})
          break
          case 'identificación':
            setIdentFilter(newFilter)
            setNewCliente({...newCliente, identificacion: event.target.value})
          break
          default:
        }
        handleFormFilter(newFilter)
      }

      const handleClickCliente = (event) => {
        event.preventDefault()
  
        const articuloToEdit = infoFormFilter.find(cli => cli.identificacion === event.target.lastChild.data)
        const objToEdit = {...newCliente, ...articuloToEdit}
  
        const formObj = {
          tipo: 'editarCliente',
          datos: objToEdit
        }
        
        handleClickFormFilter(formObj)
        setNewCliente(formObj.datos)
        setNombreFilter({value: formObj.datos.nombre, state: false})
        setIdentFilter({value: formObj.datos.identificacion, state: false})
      }

    return (
      <div className="ventanaEmergente">
        <div className='formulario'>
            <h2>{title} cliente</h2>
            <form className="formInputs" onSubmit={handleCliente}>
            <div>             
                    Nombre: <input onChange={handleChangeFilter} value={nombreFilter.value}></input>
                    {
                        nombreFilter.state === true ? 
                        (                    
                          <div>
                          {
                              infoFormFilter.map((cliente) => (
                                  <div key={cliente.identificacion} onClick={handleClickCliente}>{cliente.nombre} {cliente.identificacion}</div>
                              ))
                          }                                                
                          </div>                 
                        ) : ('')                    
                    }
                </div>
                <div>             
                    Identificación: <input onChange={handleChangeFilter} value={identFilter.value}></input>
                    {
                        identFilter.state === true ? 
                        (                    
                          <div>
                          {
                              infoFormFilter.map((cliente) => (
                                  <div key={cliente.identificacion} onClick={handleClickCliente}>{cliente.nombre} {cliente.identificacion}</div>
                              ))
                          }                                                
                          </div>                 
                        ) : ('')                    
                    }
                </div>                          
                <div className='accionesForm'>
                  <button className='botonGeneral'>aceptar</button>
                  <button className='botonGeneral' onClick={actionCancelar}>cancelar</button>
                </div>
            </form>
        </div>
        </div>
    )
}

export default ClienteRegRapido;