import React, { useState } from 'react'
import { FiXCircle } from 'react-icons/fi'
import PropTypes from 'prop-types'

export const Cliente = (props) => {
  const {
    nombre,
    identificacion,
    telefono,
    correo,
    direccion
  } = props.object

  const {
    actionEditar,
    actionEliminar
  } = props

  const [botonDetalle, setBotonDetalle] = useState(false)

  return props.type === 'grande'
    ? <tr>
        <td>{nombre}</td>
        <td>{identificacion}</td>
        <td>{telefono}</td>
        <td>{correo}</td>
        <td>{direccion.ciudad}</td>
        <td>{direccion.direccion}</td>        
        <td>
          <button className="botonGeneral" onClick={actionEditar}>editar</button>
          <button className="botonGeneral" onClick={actionEliminar}>eliminar</button>
        </td>
    </tr>
    : <div>
      <li onClick={() => setBotonDetalle(true)} key={identificacion}>{nombre} - {identificacion}</li>
      {botonDetalle === true
        ? <div className="ventanaEmergente">
          <div className="formulario">
            <button className='botonCerrar' onClick={() => setBotonDetalle(false)}><i><FiXCircle/></i></button>
            <table>
              <tbody>
                <tr>
                  <td>nombre:</td>
                  <td>{nombre}</td>
                </tr>
                <tr>
                  <td>identificación:</td>
                  <td>{identificacion}</td>
                </tr>
                <tr>
                  <td>correo:</td>
                  <td>{correo}</td>
                </tr>
                <tr>
                  <td>télefono:</td>
                  <td>{telefono}</td>
                </tr>
                <tr>
                  <td>ciudad:</td>
                  <td>{direccion.ciudad}</td>
                </tr>
                <tr>
                  <td>departamento:</td>
                  <td>{direccion.departamento}</td>
                </tr>
                <tr>
                  <td>dirección:</td>
                  <td>{direccion.direccion}</td>
                </tr>
              </tbody>
            </table>
            <div>
              <button className='botonGeneral' onClick={actionEditar}>editar</button>
              <button className='botonGeneral' onClick={actionEliminar}>eliminar</button>
            </div>
          </div>
        </div>
        : ''
      }
    </div>
}

Cliente.propTypes = {
  type: PropTypes.string,
  object: PropTypes.object,
  actionEditar: PropTypes.func,
  actionEliminar: PropTypes.func
}

export default Cliente
