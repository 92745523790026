import { primeraLetraMayuscula } from './utils'

export const crud = (
  dataToSet,
  repoInterface,
  actionStateChange
) => {
  const add = (obj, objInit, filteredData, type) => {
    const clienteToAdd = { ...objInit, ...obj }
    const dataSet = dataToSet(filteredData)
    repoInterface
      .add(type, clienteToAdd)
      .then(response => actionStateChange().success(
        dataSet.add(response).newData,
        objInit,
        {
          value: `${primeraLetraMayuscula(type)} añadido(a)`,
          type: 'success'
        }
      ))
      .catch(error => actionStateChange().error({
        value: error.response.data.error,
        type: 'failure'
      }))
  }

  const edit = (obj, objInit, data, filteredData, type) => {
    const clienteToedit = data.find(n => n.id === obj.id)
    const clienteToAdd = {
      ...clienteToedit,
      ...obj,
      activo: true
    }
    const dataSet = dataToSet(filteredData)

    repoInterface
      .edit(type, clienteToAdd.id, clienteToAdd)
      .then(response => actionStateChange().success(
        dataSet.edit(clienteToAdd, response).newData,
        objInit,
        {
          value: `${primeraLetraMayuscula(type)} ${response.nombre} se editó`,
          type: 'success'
        }
      ))
      .catch(error => actionStateChange().error({
        value: error.response.data.error,
        type: 'failure'
      }))
  }

  const eliminate = (id, objInit, data, filteredData, type) => {
    const clienteToDelete = data.find(n => n.id === id)
    const modCliente = { ...clienteToDelete, activo: false }
    const dataSet = dataToSet(filteredData)

    repoInterface
      .edit(type, id, modCliente)
      .then(response => actionStateChange().success(
        dataSet
          .edit(modCliente, response)
          .newData
          .filter(d => d.activo),
        objInit,
        {
          value: `${primeraLetraMayuscula(type)} ${response.nombre} se eliminó`,
          type: 'failure'
        }
      ))
      .catch(error => actionStateChange().error({
        value: error.response.data.error,
        type: 'failure'
      }))
  }

  return { add, edit, eliminate }
}
