import clientesService from '../services/clientes'
import usuariosService from '../services/usuarios'
import productosService from '../services/productos'
import ventasService from '../services/ventas'

const repoType = {
  cliente: clientesService,
  usuario: usuariosService,
  producto: productosService,
  venta: ventasService
}

const getAll = (type) => {
  const repo = repoType[type]
  return repo.getAll()
}

const add = (type, object) => {
  const repo = repoType[type]
  return repo.create(object)
}

const edit = (type, id, object) => {
  const repo = repoType[type]
  return repo.update(id, object)
}

const exportedObject = { getAll, add, edit }

export default exportedObject
