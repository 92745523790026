export const filterFunction = (value) => {
  const hasValue = (d) => d
    .filter(obj => JSON.stringify(Object.values(obj))
      .toLowerCase()
      .includes(value.toLowerCase()))

  const equalsTo = (d, properties) => {
    const a = {}
    hasValue(d)
      .map(obj => Object.keys(properties)
        .forEach(k => obj[k]
          .toLowerCase()
          .includes(value.toLowerCase()) &&
          (a[k] = obj[k])))
    return a
  }

  const isActive = (d) => d
    .filter((obj) => obj.activo)

  return { hasValue, isActive, equalsTo }
}

export const sortData = (x, y) => {
  if (x.nombre < y.nombre) { return -1 }
  if (x.nombre > y.nombre) { return 1 }
  return 0
}

export const dataToSet = (dataSet) => ({
  add: (returnedObj) => ({
    newData: [...dataSet, returnedObj]
  }),
  edit: (objToAdd, returnedObj) => ({
    newData: dataSet.map(
      cliente => cliente.id !== objToAdd.id
        ? cliente
        : returnedObj
    )
  })
})

export const duplicateObj = (obj, reqProps, data) => {
  Object.keys(reqProps).forEach((key) => {
    const value = typeof obj[key] === 'object'
      ? JSON.stringify(obj[key])
      : obj[key]
    const duplicate = filterFunction(value)
      .equalsTo(data, reqProps)
    reqProps[key] = duplicate[key] || false
  })
  return reqProps
}
