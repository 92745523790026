import { Link } from "react-router-dom";

export const LoginForm = (props) => {


    return (
        <div className='loginPage'>
            <div className='loginForm'>
                <h2>Iniciar sesión</h2>
                <form onSubmit={props.loginAction}>        
                    <div className="userLogin">          
                        Usuario:          
                        <input                                
                            type="text"            
                            value={props.usuario}
                            name="Username"            
                            onChange={props.usernameAction}          
                        />        
                    </div>        
                    <div className="passwordLogin">          
                        Contraseña:
                        <input                    
                            type="password"
                            value={props.contrasena}
                            name="Password"
                            onChange={props.passwordAction}
                        />
                    </div>
                    <button className='botonPrincipal' type="submit">ingresar</button>
                </form>
                <Link to="/password-reset">¿Olvidó su contraseña?</Link>
            </div>                 
        </div>
    )
}

export default LoginForm;