import React, { useState } from 'react'
import { searchFunction } from './searchFunction.js'
import productosService from '../services/productos'
import * as XLSX from 'xlsx'
import PropTypes from 'prop-types'

export const ProductoMultForm = ({
  title,
  setForm,
  productos,
  actionCancelar
}) => {
  const [itemsToReg, setItemsToReg] = useState({ good: [], duplicates: [], toEdit: [], registered: [], edited: [], error: [], resultarray: [] })

  const validateMessage = (object) => {
    const arrayResultado = []

    Object.entries(object).forEach(([key, value]) => {
      if (value) {
        arrayResultado.push(
          `El ${'producto'} con ${key} ${value} se encuentra registrado`
        )
      }
    })
    return arrayResultado
  }

  const validateUndefined = (value) => {
    if (typeof value === 'undefined') {
      return ''
    } else {
      return value.toString()
    }
  }

  const uploadExcelFile = (event) => {
    const file = event.target.files[0]

    const promise = new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsArrayBuffer(file)

      reader.onload = function (event) {
        const data = event.target.result
        const wb = XLSX.read(data, { type: 'binary' })
        const sheetInner = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])
        resolve(sheetInner)
      }
    })

    promise.then((d) => {
      const regItems = []
      const repeatedItems = []
      const itemsToActivate = []

      for (const i in d) {
        const listObj = {
          nombre: validateUndefined(d[i].nombre),
          codigo: validateUndefined(d[i].codigo),
          descripcion: validateUndefined(d[i].descripcion),
          inventario: parseInt(validateUndefined(d[i].inventario)),
          costo: parseInt(validateUndefined(d[i].costo)),
          precioVenta: parseInt(validateUndefined(d[i].precioVenta)),
          categoria: validateUndefined(d[i].categoria),
          imagen: validateUndefined(d[i].imagen) === '' ? 'img/not_available.jpg' : d[i].imagen.toString(),
          activo: true
        }

        const prodRep = searchFunction(productos, listObj, 'producto')
        const duplicates = validateMessage(prodRep)

        if (duplicates.length === 0) {
          regItems.push(listObj)
        } else {
          const prodToEdit = productos.find(prod => (prod.nombre.toLowerCase() === listObj.nombre.toLowerCase()))
          const newProd = { ...listObj, imagen: prodToEdit.imagen }
          if (prodToEdit.activo === true) {
            repeatedItems.push(newProd)
          } else {
            itemsToActivate.push(newProd)
          }
        }
      }
      setItemsToReg({ ...itemsToReg, good: regItems, duplicates: repeatedItems, toEdit: itemsToActivate })
    })
  }

  const addMultProd = (event) => {
    event.preventDefault()

    const registered = []
    const errItems = []
    const edited = []
    const resultArray = productos

    for (const i in itemsToReg.good) {
      productosService
        .create(itemsToReg.good[i])
        .then(returnedProduct => {
          registered.push(returnedProduct)
        })
        .catch(error => {
          console.log(error.response.data)
          errItems.push(itemsToReg.good[i])
        })
    }

    if (itemsToReg.toEdit.length > 0) {
      for (const i in itemsToReg.toEdit) {
        const idProducto = productos.find(prod => (prod.nombre.toLowerCase() === itemsToReg.toEdit[i].nombre.toLowerCase()))
        const indexProd = resultArray.indexOf(idProducto)

        productosService
          .update(idProducto.id, itemsToReg.toEdit[i])
          .then(returnedProduct => {
            edited.push(returnedProduct)
            resultArray[indexProd] = returnedProduct
          })
          .catch(error => {
            console.log(error.response.data)
            errItems.push(itemsToReg.toEdit[i])
          })
      }
    }
    console.log(resultArray)
    const formArray = {
      duplicates: itemsToReg.duplicates,
      registered,
      edited,
      error: errItems,
      resultarray: resultArray
    }
    setItemsToReg({ ...itemsToReg, formArray })
    setForm({ ...formArray })
  }

  return (
    <div className="ventanaEmergente">
      <div className='formulario'>
      <h2>{title}</h2>
        <p>
            Esta opción permite cargar varios productos por medio de un archivo de excel,
            <br></br>
            el archivo debe tener el mismo formato que se especifica en la siguiente imagen:
        </p>
        <img src='img/productosTemplate.JPG' alt='imagen no disponible' width="600" height="80"/>
        <form onSubmit={addMultProd}>
          <input type="file" onChange={uploadExcelFile} />
          <div>
              <button className='botonGeneral'>enviar</button>
              <button className='botonGeneral' onClick={actionCancelar}>cancelar</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ProductoMultForm

ProductoMultForm.propTypes = {
  title: PropTypes.string,
  setForm: PropTypes.func,
  productos: PropTypes.array,
  actionCancelar: PropTypes.func
}
